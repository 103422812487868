<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\Acountactive.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-23 16:07:51
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-01-23 16:09:34
-->
<template>
  <div class="active">
    <Banner></Banner>
    <Active></Active>
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/Home/Banner.vue";
import Active from "@/components/AcountActive/index.vue";

export default {
  name: "Home",
  components: { Active, Banner },
};
</script>
