<template>
  <div class="agreement">
    <h2>Storytrans Privacy Policy Agreement</h2>
    <h3>Version Release Date: June 18, 2024</h3>
    <p>Storytrans.com (hereinafter referred to as "Storytrans", "we" or "us") is fully aware of the importance of personal information to the users of the Storytrans platform (hereinafter referred to as "users" or "you"), and we appreciate your trust. The "Storytrans Privacy Policy Agreement" (hereinafter referred to as "this Agreement") is closely related to your use of our related products/services. We recommend that you read and understand all the contents of this Agreement carefully and make the choices you deem appropriate. Once you use or continue to use our products/services, it means that you have fully understood and unconditionally accepted all the contents of this Agreement. If you do not want your personal information to be used in these ways, please stop accessing Storytrans and stop using our products and services.</p>
    <h4>1. Data Collection and Use</h4>
    <p>To help you create an account on Storytrans and use our platform, we need to collect and process some personal information. "Personal information" is any information that can identify your identity and help us provide you with related services on Storytrans. This may include, but is not limited to, your personal details (such as real name, gender, age, valid identity document information,  professional experience, etc.), contact information (such as phone number, email address, mailing address, social media accounts, etc.), test or certification results, internet protocol ("IP") address, payment information (such as payment method, bank card information, transaction records, etc.), and other information you fill in on Storytrans. You  acknowledge and agree that we use your personal information to:</p>
    <p>(1) create your Storytrans account and provide our services to you;</p>
    <p>(2) identify you across the platform;</p>
    <p>(3) provide you with content on the platform;</p>
    <p>(4) provide technical support services to you;</p>
    <p>(5) communicate with you (e.g., to keep you informed of upcoming changes or improvements);</p>
    <p>(6) verify your identity to grant you authority to receive translation or proofreading tasks on the platform;</p>
    <p>(7) recommend tasks suitable for your skills;</p>
    <p>(8) make payments to you based on the tasks you have completed;</p>
    <p>(9) calculate anonymous, aggregate statistics to better understand our users, improve our services, develop new features, and personalize and customize your story discovery experience;</p>
    <p>(10) help prevent spam, fraud, abuse, and other prohibited activities;</p>
    <p>(11) operate, develop, and improve our services (for security purposes).</p>
    <p>Among the aforementioned personal information, identification numbers, biometric data, bank or other financial account details, property information, tracking data, transaction data, and personal information of children under the age of 14 (inclusive) are considered sensitive personal information.</p>
    <p>If the information you provide includes the personal information of other users, you must ensure that you have obtained legal authorization before providing us with this personal information. If it involves the personal information of children, you must obtain the consent of the corresponding child's guardian before publishing. In such cases, the guardian has the right to contact us through the means specified in Article 8 of this Agreement to request correction or deletion of the content involving the child's personal information.</p>
    <h4>2. Information Sharing</h4>
    <p>Except for your personal profile information (such as avatar, nickname, gender, birthday, language proficiency, location, the profile description you create, etc.), we default your other personal information as private, therefore it will not be displayed on the platform (unless you choose to display applicable information through account settings) or disclosed to other Storytrans users (except anonymized information and data).</p>
    <p>We will not rent or sell your personal identifying information to other companies or individuals, unless we have your consent, except in the following very limited cases:</p>
    <p>(1) To comply with applicable law (including any criminal investigation), regulations, legal processes, or enforceable governmental requests;</p>
    <p>(2) To enforce or apply our terms of use and other policies, including for investigating potential violations;</p>
    <p>(3) To protect the rights, property, or safety of Storytrans, our employees, our users, or the public;</p>
    <p>(4) To provide relevant information to affiliated companies within the necessary scope for business cooperation with us.</p>
    <h4>3. Information Security</h4>
    <p>We continuously implement and update physical, organizational, contractual, and technical security measures to protect your personal information from loss, theft, unauthorized access, disclosure, copying, use, or modification. The only employees who are granted access to your personal information are those with a business "need-to-know" or whose duties reasonably require such information. On all Storytrans platforms, we support encryption of all transmissions to protect personal information submitted on our platform.</p>
    <h4>4. Non-Personal Information</h4>
    <p>(1) Storytrans uses cookies and similar technologies on our platform to collect non-personally identifiable information ("Non-Personal Information") to help us provide our services and understand how our services are used. We also use these technologies to help deliver promotional information and advertisements about Storytrans products and services. Most web browsers automatically accept cookies, but you can usually modify your browser's settings to decline cookies according to your needs and the capabilities of your browser.</p>
    <p>(2) We may use Non-Personal Information and share it with third parties (such as our partners). For example, we might show our partners general usage trends on our platform. The processing of this Non-Personal Information cannot enable any third party to identify you.</p>
    <h4>5. Data Retention</h4>
    <p>(1) If we need to transfer your personal information overseas to provide you with certain services or features, we will obtain your explicit consent in advance and carry out such transfers in strict accordance with applicable laws and regulations; for these situations, we ensure that your personal information is adequately protected according to this Agreement.</p>
    <p>(2) We retain your personal information only for the minimum period necessary to achieve the purposes described in this Agreement, unless legally required to retain it longer. After the retention period for your personal information has expired, we will delete or anonymize your personal information according to applicable legal requirements.</p>
    <p>(3) If our platform decides to cease operations of certain services, we will stop collecting your personal information after the related services have ceased. We will notify you of the cessation of operations via announcements or emails. For the personal information we already hold, we will delete or anonymize it, unless otherwise provided by laws and regulations. In the case of children's personal information, we will take necessary and reasonable measures to promptly inform the children's guardians of the cessation.</p>
    <h4>6. Personal Rights Requests</h4>
    <p>In certain circumstances, you have the right to request us to satisfy one or more of the following requests. Before fulfilling your request, we may ask you to provide proof of identity or other information. </p>
    <p>(1) Inquiry and Correction Rights: You can request details of the information we hold about you and a copy of your information, and you can also apply for correction of any errors in that information.</p>
    <p>(2) Right to Deletion ("Right to Be Forgotten"): In certain situations, you have the right to request deletion of your information. In some cases, we may not be able to delete certain types of information, especially if we have a legal obligation to retain this information (e.g., for regulatory reporting purposes) or if you want us to continue to provide certain products/services for which the processing of information is necessary.</p>
    <p>(3) Right to Withdraw Consent: For any processing of information for which we have obtained your consent, other than retention (for retention, please refer to the previous item), you can withdraw your consent at any time. If you withdraw your consent for us to process some of your information, it may result in the inability to exercise rights and/or bear obligations under other agreements (such as the "Storytrans Service Agreement") we have entered into (for example, if you revoke your consent for us to use your payment information, it may cause us to be unable to make payments for tasks you have completed). You fully understand and agree that if this results in you breaching the agreements we have made under this Agreement or other agreements, you will need to bear the corresponding liability for breach of contract; if this results in us being unable to fulfill the obligations under this Agreement or other agreements, we are not required to bear any liability.</p>
    <p>We will evaluate any requests to exercise rights on a case-by-case basis. In some cases, under exemptions provided by applicable data protection laws, we may not have a legal obligation to fulfill these requests. This may mean that we can retain your information even if you withdraw consent. If you wish to exercise these rights, please contact us.</p>
    <h4>7. Changes to the Privacy Policy</h4>
    <p>Storytrans reserves the right to change this privacy policy at any time, and any changes will take effect immediately upon posting, without further notice. We encourage you to regularly review and check this Agreement to inform yourself of the latest content; if you do not agree with this Agreement and/or the revised agreement, please stop using our services.</p>
    <h4>8. Contact Us</h4>
    <p>If you have any questions, comments, or suggestions about the content of this Privacy Policy, please contact us via email at inknettranslation@gmail.com.</p>

  </div>
</template>

<script>
export default {
    name: 'ServiceAgreement',
    mounted () {
        this.$root.$children[0].toggleDialogHandle({
          showTop: false,
          showBottom: false
        });
    },
    destroyed () {
        this.$root.$children[0].toggleDialogHandle({
          showTop: true,
          showBottom: true
        });
    }
}
</script>

<style lang="less" scoped>
.agreement {
  width: 970px;
  margin: 0 auto;
  padding-bottom: 50px;
  word-break: bread;

  h2 {
    margin: 10px 0;
  }
  h3 {
    margin-bottom: 16px;
  }
  h4 {
    margin-top: 16px;
  }
  p {
    margin: 8px 0;
  }
}
</style>
