<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\Fy.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-22 15:39:50
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-04 10:32:50
-->
<template>
  <div class="fy fy2">
    <div class="content w">
      <div class="title">{{ $t("fy.proofreadingTest") }}</div>
      <div class="detail clearfix">
        <div class="base fl">
          <div class="dtitle base_title">{{ $t("fy.originTxt") }}</div>
          <div class="dcontent origin" ref="stopCopy">
            <p v-for="(tt, i) in p_list" :key="i" style="margin-bottom: 10px">
              {{ tt }}</p
            >
          </div>

          <!-- <div class="dcontent">
            天上没有星子，更没有月亮，漆黑得像一个大洞，让人有些颠倒分不清上下，似乎一失脚就要坠进去。
            “南无阿弥陀佛南无阿弥陀佛……”一个莫约十二三岁的小孩孤零零一个人在路上急速的走着。右手握着一小串佛珠碎碎的念，左手提着个油皮灯笼。
            与其说她是走不如说是在跑，因为那些东西一直在后面跟着她，只是因为佛珠的原因不敢太靠近。周围漆黑一片，只看得见灯笼荧荧鬼火一般在半空中飘移着。四野寂静得有些诡异，连流水声，虫鸣声都听不见。
            马上就要到村子里了，进了村就好了，小孩不断提醒自己，苍白着脸，冷汗直往下掉。腾出右手把身上披的八只黑狗皮拼制成的披风裹得更严实一点，妄图不让自己的气味更多的散发出去。
            可是走到村头的小石拱桥上，小孩还是傻那里了。一个打着纸伞的女人站在桥上正对着她，伞面上绣的是红得耀眼的桃花，白色的衣裙上也是。伞打得很低，看不见脸。明明是炎热酷闷得没有一点风，可是那裙袂却激荡的上下翻飞着。
            小孩吓得停在那里双腿直打颤，完了，遇上鬼拦路了。
            “南无阿弥陀佛……”她继续低声念着，侧过身子想从桥另一边过，低下头装作没看见她。却发现她眨眼间又站在了她的面前。精致的白色绣花鞋上沾满了泥，脚边是一滩的水，还有各种绿色的水藻和贝壳。这时她才看清，那裙·摆上的哪是桃花，分明是溅染的鲜血。
            突然，手中灯笼本应该温柔的黄光开始诡异的从青色变成红色，好像也被血染过了一样。空气里满是刺鼻的河里的膻气与血的腥臭。
            “南无阿弥陀佛……”硬着头皮把佛珠举到前面，那女鬼退了两步，小孩又前进两步，那女鬼又退两步。快到桥头时，却听一阵令人毛骨悚然的笑声响起。
          </div> -->
        </div>
        <div class="current fr">
          <div class="dtitle cu_title">{{ $t("fy.transTxt") }}</div>
          <div class="dcontent cu_content"  v-if="bookInfo.type == 1" v-html="bookInfo.answer"></div>
          <div class="dcontent cu_content" v-else v-html="formDataValue.fyContent"></div>
        </div>
      </div>
    </div>
    <div class="tranlate-article w">
      <div class="title">{{ $t("fy.translationRes") }}</div>
      <vue-editor v-model="formDataValue.fyContent" :editor-toolbar="customToolbar"></vue-editor>
    </div>
    <div class="process w">
      <div class="title">{{ $t("fy.reviewRes") }}</div>
      <div class="content">
        <el-row :gutter="10" class="mb">
          <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"><div class="sub-title">{{ $t("fy.reviewRes") }}</div></el-col>
          <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
            <el-select v-model="formDataValue.auditStatus" :placeholder="$t('fy.pleaseSelect')">
              <el-option value="1" :label="$t('fy.reviewPass')"></el-option>
              <el-option value="2" :label="$t('fy.refuse')"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row class="" :gutter="10">
           <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"><div class="sub-title">{{ $t("fy.comment") }}</div></el-col>
            <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
              <el-input
                type="textarea"
                :rows="4"
                maxlength="255"
                show-word-limit
                :placeholder="$t('fy.commentTip')"
                v-model="formDataValue.auditOpinion">
              </el-input>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="info w">
      <div class="title">{{ $t("fy.info") }}</div>
      <div class="form clearfix">
        <!-- 左侧 -->
        <div class="left fl">
          <!-- 简历 -->
          <div class="item jianli">
            <div class="tit"><i>*</i>&nbsp;&nbsp;{{ $t("fy.uploadCV") }}</div>
            <div class="con clearfix">
              <div class="l fl">
                <input
                  type="file"
                  name="resume"
                  id="resume"
                  ref="resume"
                  @change="chfiles"
                />
                <i class="el-icon-circle-plus-outline"></i>
                <span>{{ $t("fy.uploadCV") }}</span>
                <p v-if="upStatus == 3" class="upS">{{ $t("fy.uploadFail") }}</p>
                <p v-if="upStatus == 2" class="upB">{{ $t("fy.uploadSuc") }}</p>
              </div>
              <a
                class="r fl"
                href="https://assets.goodnovel.com/doc/CVTemplate.doc"
                target="_blank"
              >
                <i class="el-icon-download"></i>
                <span>{{ $t("fy.downloadCV") }}</span>
              </a>
            </div>
          </div>
          <!-- 生活年数 -->
          <div class="item gwsh h64">
            <div class="tit">&nbsp;&nbsp;{{ $t("fy.abroad") }}</div>
            <!-- 英文提示过长，使用textarea进行自动换行处理 -->
            <textarea
              class="con"
              type="number"
              maxlength="2"
              :placeholder="$t('fy.abroadTip')"
              v-model="formDataValue.lifeAbroadYear"
            ></textarea>
          </div>
          <!-- 语言证书 -->
          <div class="item yyzs">
            <div class="tit"><i>*</i>&nbsp;&nbsp;{{ $t("fy.certificate") }}</div>
            <textarea
              class="con"
              type="number"
              :placeholder="$t('fy.enterContent')"
              length="400"
              v-model="formDataValue.languageCertificate"
            />
            <div class="word_count">
              {{ formDataValue.languageCertificate.length }}/400
            </div>
          </div>
          <!-- 翻译经验 -->
          <div class="item gwsh">
            <div class="tit"><i>*</i>&nbsp;&nbsp;{{ $t("fy.wordCount") }}</div>
            <input
              class="con"
              type="number"
              :placeholder="$t('fy.numOnly')"
              v-model="formDataValue.fyWordNum"
            />
          </div>
          <!-- 外籍不显示钉钉 -->
          <div v-if="$i18n.locale === 'cn'" class="item yyzs cigj">
            <div class="tit">{{ $t("fy.dingding") }}</div>
            <input
              class="con"
              type="text"
              :placeholder="$t('fy.dingdingTip')"
              v-model="formDataValue.ding"
            />
          </div>
        </div>
        <div class="right fr">
          <!-- 难点 -->
          <div class="item yyzs nd">
            <div class="tit">
              <i>*</i>&nbsp;&nbsp; {{ $t("fy.difficult") }}
            </div>
            <textarea
              class="con"
              :placeholder="$t('fy.enterContent')"
              length="400"
              v-model="formDataValue.testExplain"
            />
            <div class="word_count">
              {{ formDataValue.testExplain.length }}/400
            </div>
          </div>
          <!-- 工具 -->
          <div class="item yyzs h64">
            <div class="tit">&nbsp;&nbsp; {{ $t("fy.tools") }}</div>
            <textarea
              class="con"
              :placeholder="$t('fy.toolsTip')"
              length="400"
              v-model="formDataValue.commonTools"
            />
            <div class="word_count">
              {{ formDataValue.commonTools.length }}/400
            </div>
          </div>
          <div class="item yyzs h64">
            <div class="tit"><i>*</i>&nbsp;&nbsp; {{ $t("fy.learnUs") }}</div>
            <textarea
              class="con"
              :placeholder="$t('fy.toolsTip')"
              length="400"
              v-model="formDataValue.recruitSource"
            />
            <div class="word_count">
              {{ formDataValue.recruitSource.length }}/400
            </div>
          </div>
          <div class="item yyzs cigiqq">
            <div class="tit"><i>*</i>&nbsp;&nbsp; {{ $t("fy.qq") }}</div>
            <textarea
              class="con"
              type="text"
              :placeholder="$t('fy.qqTip')"
              v-model="formDataValue.qq"
            ></textarea>
          </div>
          <div class="item yyzs h64">
            <div class="tit"><i>*</i>&nbsp;&nbsp; {{ $t("fy.phone") }}</div>
            <!-- 英文提示过长，使用textarea进行自动换行处理 -->
            <textarea
              class="con"
              type="number"
              :placeholder="$t('fy.phoneTip')"
              v-model="formDataValue.phone"
            ></textarea>
          </div>
        </div>
        <div class="sub fl">
          <div :class="{ btn: true }" @click="formUpload">{{ $t("fy.submit") }}</div>
        </div>
      </div>
    </div>
    <div class="warm" v-show="isModel">
      <img
        src="../assets/ic_close.png"
        class="close"
        alt=""
        @click="isModel = false"
      />
      <img src="../assets/ic_warning.png" class="warmImg" alt="" />
      <p>{{ $t("fy.noCopy1") }}</p>
      <p>{{ $t("fy.noCopy2") }}</p>
    </div>
  </div>
</template>
<script>
import { taskSaveTranslate, uploadResumeFile, taskDetail } from "@/api/task";
import Vue from "vue";
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);
export default {
  name: "review",
  data() {
    return {
      transContent: "", // 翻译内容
      imageUrl: "",
      customToolbar: [
        ["bold", "italic", "underline"],
        [{background:[]},{color:[]}],
      ],
      formLabelAlign: {
        resume: false, //简历
        years: false,
        certificate: "", // 证书
        words: false // 单词数
      },
      formDataValue: {
        fyContent: "",
        resumePath: "",
        lifeAbroadYear: "",
        languageCertificate: "",
        fyWordNum: "",
        testExplain: "",
        commonTools: "",
        recruitSource: "",
        qq: "",
        phone: "",
        fttId: "",
        ding: "",
        auditOpinion: "",
        auditStatus: "1",
      },
      bookInfo: {
        content: ""
      },
      sta: true,
      upStatus: 1, //1 未操作默认 2 上传成功 3 上传失败
      p_list: [],
      isModel: false
    };
  },
  mounted() {
    this.doStopCopy();
    this.getTestData();
  },
  methods: {
    dealWordCount(content) {
      if (!content) {
        return 0;
      }
      if (typeof content != "string") {
        content = new String(content);
      }
      content = content.replace(/[^a-zA-Z\s+]/g, " ");
      let count = content.split(/\s+/g);
      count = count.filter(item => {
        return item;
      });
      return count.length == 0 ? 0 : count.length;
    },
    doStopCopy() {
      let _this = this;
      this.$nextTick(() => {
        // 禁用右键
        _this.$refs.stopCopy.oncontextmenu = function(e) {
          let event = e || window.event;
          event.returnValue = false;
          _this.isModel = true;
        };
        // 禁用选择
        _this.$refs.stopCopy.onselectstart = function(e) {
          let event = e || window.event;
          event.returnValue = false;
          _this.isModel = true;
        };

        // ctrl + c
        _this.$refs.stopCopy.onkeydown = function(e) {
          let event = e || window.event;
          if (event.ctrlKey && event.keyCode == 67) {
            _this.isModel = true;
            event.returnValue = false;
          }
        };
      });

      document.addEventListener("keydown", function(e) {
        let event = e || window.event;
        if (event.ctrlKey && event.keyCode == 65) {
          _this.isModel = true;
          event.returnValue = false;
        }
      });
    },
    getTestData() {
      let _this = this;
      let bookId = this.$route.params.id || false;
      this.$axios({
        url: taskDetail,
        method: "post",
        data: { id: bookId }
      })
        .then(res => {
          if (res.data.status == 0) {
            _this.bookInfo = res.data.data;
            let content = _this.bookInfo.content || "";
            _this.p_list = content.split("\n");
          } else {
            this.$message.error(this.$t("reqPageErr"));
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    chfiles() {
      let _this = this;
      let formData = new FormData();
      let file = this.$refs.resume.files[0];
      if (!this.beforeAvatarUpload(file)) {
        return;
      }
      formData.append("resumeFile", file);

      this.$axios({
        url: uploadResumeFile,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data"
        },
        data: formData
      })
        .then(res => {
          this.$refs.resume.value = "";
          if (res.data.status == 0) {
            this.$message.success(this.$t("fy.uploadSuc1"));
            this.upStatus = 2;
            _this.formDataValue.resumePath = res.data.data;
          } else if (res.data.status == 1007) {
            this.callLogin();
          } else {
            this.upStatus = 3;
            this.$message.error(
              this.$t("fy.uploadRetry") + res.data.message
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isDOC =
        file.name.lastIndexOf(".doc") > -1 ||
        file.name.lastIndexOf(".docx") > -1 ||
        file.name.lastIndexOf(".DOC") > -1 ||
        file.name.lastIndexOf(".DOCX") > -1 ||
        file.name.lastIndexOf(".docX") > -1 ||
        file.name.lastIndexOf(".pdf") > -1 ||
        file.name.lastIndexOf(".PDF") > -1;
      const isLt30M = file.size / 1024 / 1024 < 30;

      if (!isDOC) {
        this.$message.error(this.$t("fy.resumeDocTip"));
        this.upStatus = 3;
      } else if (!isLt30M) {
        this.$message.error(this.$t("fy.resumeSizeTip"));
        this.upStatus = 3;
      }
      return isDOC && isLt30M;
    },
    beforeCheck() {
      var mappingObjName = {
        fyContent: this.$t("fy.contentCheck"),
        resumePath: this.$t("fy.resumeCheck"),
        lifeAbroadYear: this.$t("fy.abroadCheck"),
        languageCertificate: this.$t("fy.certificateCheck"),
        fyWordNum: this.$t("fy.wordCountCheck"),
        testExplain: this.$t("fy.difficultCheck"),
        commonTools: this.$t("fy.toolsCheck"),
        recruitSource: this.$t("fy.learnUsCheck"),
        qq: this.$t("fy.qqCheck"),
        phone: this.$t("fy.phoneCheck"),
        fttId: this.$t("fy.fttIdCheck"),
        auditOpinion: this.$t("fy.commentCheck")
      };
      
      let formDataValue = this.formDataValue;
      for (var i in formDataValue) {
        //所有为空的警告处理;
        let nomust = ["lifeAbroadYear", "commonTools", "ding"];
        if (!nomust.includes(i) && formDataValue[i].length == 0) {
          this.$message.error(mappingObjName[i]);
          this.sta = true;
          break;
        }

        //翻译 大于300 才能提交
        // if (this.dealWordCount(formDataValue["fyContent"]) < 300) {
        //   this.sta = true; //不能提交
        //   this.$message.error("译文不能少于300个单词");
        //   break;
        // }

        //国外生活经历 number 校验
        if (i == "lifeAbroadYear") {
          let formDataValue = this.formDataValue;
          let reg = /^\d{1,}$/;
          let pattern = new RegExp(reg);
          let isNum = pattern.test(formDataValue[i]); //不是数字
          if (!formDataValue[i] || isNum) {
            this.sta = false;
            formDataValue[i] = !formDataValue[i]
              ? 0
              : parseInt(formDataValue[i]);
          } else {
            this.sta = true; //不能提交
            this.$message.error(this.$t("fy.abroadCheck1"));
          }
        }

        //单词词汇量 number 校验
        if (i == "fyWordNum") {
          let formDataValue = this.formDataValue;
          let reg = /^\d{1,}$/;
          let pattern = new RegExp(reg);
          let isNum = !pattern.test(formDataValue[i]); //不是数字
          if (isNum) {
            this.sta = true; //不能提交
            this.$message.error(this.$t("fy.translateCountCheck1"));
            break;
          } else {
            this.sta = false;
            formDataValue[i] = parseInt(formDataValue[i]);
          }
        }

        // QQ号11位以内与 number 校验
        if (i == "qq" && this.$i18n.locale === "cn") {
          let formDataValue = this.formDataValue;
          let qqReg = /^[1-9]\d{4,10}$/;
          let wxReg = /^[a-zA-Z_-]{1}[a-zA-Z0-9_-]{5,19}$/;
          let qqPattern = new RegExp(qqReg);
          let wxPattern = new RegExp(wxReg);
          let isNum = !qqPattern.test(formDataValue[i]); //不是数字
          let isWx = !wxPattern.test(formDataValue[i]);
          if (isNum && isWx) {
            this.sta = true; //不能提交
            this.$message.error(this.$t("fy.qqCheck1"));
            break;
          } else {
            this.sta = false;
          }
        }

        // 手机号位数与 number 校验
        const lang = this.$i18n.locale;
        if (i == "phone" && lang == "cn") {
          let formDataValue = this.formDataValue;
          let reg = /^\d{1,}$/;
          let pattern = new RegExp(reg);
          let isNum = !pattern.test(formDataValue[i]);

          let phoneLenth = formDataValue[i].length;
          if ((phoneLenth > 0 && phoneLenth < 11) || isNum) {
            this.sta = true; //不能提交
            this.$message.error("手机号应为11位纯数字");
            break;
          } else {
            this.sta = false;
            formDataValue[i] = parseInt(formDataValue[i]);
          }
        }
        if (i == "ding") {
          let reg = new RegExp(/^[a-zA-Z][0-9a-zA-Z]{5,19}$/);
          let dingValue = formDataValue[i];
          if (dingValue && !reg.test(dingValue)) {
            this.sta = true; //不能提交
            this.$message.error("请输入正确的钉钉号");
            break;
          } else {
            this.sta = false;
          }
        }
      }
      return this.sta;
    },
    formUpload() {
      let _this = this;
      this.formDataValue.fttId = this.$route.params.id || false;
      // this.beforeCheck();
      if (this.beforeCheck()) {
        return;
      }
      this.$axios({
        url: taskSaveTranslate,
        method: "post",
        headers: {
          "Content-Type": "application/json;charset=UTF-8"
        },
        data: {
          fyContent: _this.formDataValue.fyContent,
          resumePath: _this.formDataValue.resumePath,
          lifeAbroadYear: _this.formDataValue.lifeAbroadYear,
          languageCertificate: _this.formDataValue.languageCertificate,
          fyWordNum: _this.formDataValue.fyWordNum,
          testExplain: _this.formDataValue.testExplain,
          commonTools: _this.formDataValue.commonTools,
          recruitSource: _this.formDataValue.recruitSource,
          qq: _this.formDataValue.qq,
          phone: _this.formDataValue.phone,
          fttId: _this.formDataValue.fttId,
          ding: _this.formDataValue.ding,
          auditOpinion: _this.formDataValue.auditOpinion,
          auditStatus: _this.formDataValue.auditStatus,
        }
      })
        .then(res => {
          if (res.data.status == 0) {
            this.$message.success(this.$t("fy.submitSuc"));
            let bookId = _this.$route.params.id;
            _this.$router.replace(`/reviewresult/${bookId}`);
          } else if (res.data.status == 1007) {
            this.callLogin();
          } else if (res.data.status == 500) {
            this.$message.error(res.data.message);
          } else {
            this.$message.error(this.$t("fy.submitErr"));
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    callLogin() {
      this.$root.$children[0].toggleDialogHandle({ dialogType: 1, show: true });
    },
    formatStr(str, length) {
      if (str.length > length) {
        return str.slice(0, length);
      } else {
        return str;
      }
    }
  },
  watch: {
    formDataValue: {
      deep: true,
      handler(newF, oldF) {
        // 删除非数字字符
        newF.lifeAbroadYear = newF.lifeAbroadYear.replace(/\D/g, "");
        newF.phone = newF.phone.replace(/\D/g, "");

        this.formDataValue.lifeAbroadYear = this.formatStr(
          newF.lifeAbroadYear,
          2
        );
        this.formDataValue.languageCertificate = this.formatStr(
          newF.languageCertificate,
          400
        );
        this.formDataValue.fyWordNum = this.formatStr(newF.fyWordNum, 7);
        this.formDataValue.testExplain = this.formatStr(newF.testExplain, 400);
        this.formDataValue.commonTools = this.formatStr(newF.commonTools, 400);
        this.formDataValue.recruitSource = this.formatStr(
          newF.recruitSource,
          400
        );
        this.formDataValue.qq = this.formatStr((newF.qq), 20);
        // 外籍电话最大长度20
        this.formDataValue.phone = this.formatStr(newF.phone, this.$i18n.locale === 'cn' ? 11 : 20);
      }
    }
  }
};
</script>
<style lang="less">
.fy2 {
  box-sizing: border-box;
  .warm {
    width: 420px;
    min-height: 152px;
    padding-bottom: 12px;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .warmImg {
      width: 38px;
      height: 38px;
      margin: 0 auto;
      margin-top: 23px;
      margin-bottom: 15px;
    }
    .close {
      width: 19px;
      height: 19px;
      position: absolute;
      top: 11px;
      right: 19px;
    }
    p {
      text-align: center;
      font-size: 15px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 27px;
    }
  }
}
.fy2 {
  padding: 21px 0;
  .content {
    padding-top: 6px;
    .title {
      height: 24px;
      font-size: 24px;
      font-weight: bold;
      color: #1077ff;
      line-height: 20px;
      margin-bottom: 26px;
      border-left: 5px solid #1077ff;
      padding-left: 10px;
    }
    .detail {
      .dtitle {
        height: 48px;
        background: #f3faff;
        box-shadow: 0px -1px 0px 0px rgba(232, 245, 255, 1);
        padding-left: 32px;
        font-size: 19px;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
      }
      .dcontent {
        height: 607px;
        overflow-y: auto;
        padding: 23px;
        font-size: 15px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #4c4c4c;
        line-height: 25px;
      }
      .base {
        width: 378px;
        border: 1px solid #e8f5ff;
      }

      .current {
        width: 569px;
        height: 607px;
        border: 1px solid #e8f5ff;
        .cu_title {
          background-color: rgba(217, 255, 236, 1);
        }
        .cu_content {
          overflow-y: auto;
          padding: 25px;
          border-bottom: 1px solid #e8f5ff;
          background-color: #e8f5ff;
          white-space: pre-wrap;
          textarea.el-textarea__inner {
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      }
    }
  }
  .process{
      padding-top: 30px;
      .title {
        height: 24px;
        font-size: 24px;
        font-weight: bold;
        color: #1077ff;
        line-height: 20px;
        margin-bottom: 26px;
        border-left: 5px solid #1077ff;
        padding-left: 10px;
      }
      .content{
            border: 1px solid #e8f5ff;
            padding: 20px;
            border-radius: 5px;
            background: #f8fafb;
            .sub-title{
              height: 40px;
              line-height: 40px;
              position: relative;
              &::after{
                content: "*";
                position: absolute;
                left: -8px;
                top: 0px;
                color: red;
              }
            }
            .mb{
              margin-bottom: 20px;
            }
      }
  }
  .tranlate-article{
      padding-top: 30px;
      .title {
        height: 24px;
        font-size: 24px;
        font-weight: bold;
        color: #1077ff;
        line-height: 20px;
        margin-bottom: 26px;
        border-left: 5px solid #1077ff;
        padding-left: 10px;
      }
  }
  .info {
    padding-top: 30px;

    .title {
      height: 24px;
      font-size: 24px;
      font-weight: bold;
      color: #1077ff;
      line-height: 20px;
      margin-bottom: 26px;
      border-left: 5px solid #1077ff;
      padding-left: 10px;
    }
    .form {
      background: #f8f9fb;
      border: 1px solid #e8f5ff;
      padding: 23px;

      .item {
        margin-bottom: 32px;
        overflow: hidden;
        position: relative;
        .tit {
          // height: 29px;
          font-size: 21px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 29px;
          margin-bottom: 13px;
          i {
            color: rgba(255, 0, 0, 1);
            font-weight: bold;
            vertical-align: baseline;
            font-style: normal;
          }
        }
        .con {
          padding: 21px 16px;
          background-color: #fff;
        }
        input,
        textarea {
          width: 433px;
          font-size: 14px;
          border: none;
          color: #4c4c4c;
          resize: none;
          outline: none;
          border: none;
          &::placeholder {
            font-size: 14px;
            font-weight: 500;
            color: rgba(76, 76, 76, 0.5);
          }
        }

        .word_count {
          position: absolute;
          bottom: 11px;
          right: 11px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(76, 76, 76, 0.3);
          line-height: 24px;
          overflow: hidden;
        }
      }

      .left,
      .right {
        width: 433px;
        .jianli {
          .con {
            height: 150px;

            .l,
            .r {
              width: 50%;
              height: 100%;
              position: relative;
              text-align: center;
              border-right: 1px solid rgba(232, 245, 255, 1);
              input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
              }
              i {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-top: 19px;
                margin-bottom: 13px;
                color: #1077ff;
                font-size: 30px;
              }
              span {
                display: block;
                font-size: 19px;
                font-weight: 500;
                color: #1077ff;
                line-height: 27px;
              }
              .upS {
                font-size: 22px;
                color: red;
              }
              .upB {
                font-size: 22px;
                color: greenyellow;
                margin-top: 5px;
              }
            }
            .r {
              border-right: none;
            }
          }
        }
        .yyzs {
          textarea {
            height: 157px;
          }
        }
        .nd {
          textarea {
            height: 117px;
          }
        }
        .cigj {
          textarea {
            height: 59px;
          }
        }
        .cigiqq {
          margin-bottom: 20px;
          textarea {
            height: 78px;
          }
        }
        .h64 {
          textarea {
            height: 64px;
          }
        }
      }

      .sub {
        width: 100%;
        margin-top: 14px;
        cursor: pointer;
        .btn {
          width: 427px;
          height: 59px;
          line-height: 59px;
          background: #1077ff;
          border-radius: 5px;
          text-align: center;
          font-size: 21px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #ffffff;
          margin: 0 auto;
        }
        .invalid {
          background: rgba(118, 115, 115, 0.3);
          color: #fff;
        }
      }
    }
  }
}
</style>
