<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\components\Home\JoinSelectSkeleton.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 15:53:07
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-03-25 16:11:34
-->
<template>
  <div>
    <div class="join_selcet_skeleton">
      <div class="tags">
        <div class="w">
          <div class="tag_item" v-for="(tag, index) in 5" :key="index"></div>
        </div>
      </div>
      <div class="tag_book">
        <div class="w clearfix">
          <div class="tag_book_item fl" v-for="(book, index) in 4" :key="index">
            <div class="inner">
              <div class="img"></div>
              <div class="book_name"></div>
              <div class="time"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "join_selcet",
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
.join_selcet_skeleton {
  box-sizing: border-box;
  .tags {
    width: 100%;
    height: 49px;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    .w {
      text-align: center;
      .tag_item {
        min-width: 50px;
        padding: 0 4px;
        height: 29px;
        line-height: 49px;
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
        margin-right: 40px;
        background: linear-gradient(
          to left,
          rgba(247, 247, 247, 1),
          rgba(247, 247, 247, 0.3),
          rgba(247, 247, 247, 0.9)
        );
        &:last-child {
          margin-right: 0;
        }
        &.active {
          font-size: 15px;
          color: rgba(16, 119, 255, 1);
          font-weight: bold;
          border-bottom: 2px solid rgba(16, 119, 255, 1);
        }
      }
    }
  }
  .tag_book {
    width: 100%;
    padding-top: 20px;
    min-height: 324px;
    .tag_book_item {
      cursor: pointer;
      width: 222px;
      box-sizing: border-box;
      padding: 23px 33px;
      margin-right: 27px;
      border-radius: 8px;
      background: linear-gradient(
        90deg,
        rgba(247, 247, 247, 0.6),
        rgba(247, 247, 247, 0.3),
        rgba(247, 247, 247, 1)
      );
      background-size: 100% 100%;
      margin-bottom: 28px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .inner {
        width: 100%;
        height: 100%;
        padding: 11px;
        background: #fff;
        position: relative;

        .img {
          width: 100%;
          height: 176px;
          margin-bottom: 8px;
          background: linear-gradient(
            90deg,
            rgba(247, 247, 247, 1),
            rgba(247, 247, 247, 0.6),
            rgba(247, 247, 247, 0.3)
          );
        }

        .book_name {
          width: 90%;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #333333;
          line-height: 21px;
          margin-bottom: 4px;
          background: linear-gradient(
            90deg,
            rgba(247, 247, 247, 1),
            rgba(247, 247, 247, 0.6),
            rgba(247, 247, 247, 0.3)
          );
        }

        .time {
          width: 40%;
          height: 17px;
          font-size: 11px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          line-height: 17px;
          margin-bottom: 4px;
          background: linear-gradient(
            90deg,
            rgba(247, 247, 247, 1),
            rgba(247, 247, 247, 0.6),
            rgba(247, 247, 247, 0.3)
          );
        }

        .actions {
          .btn {
            display: inline-block;
            width: 47px;
            height: 23px;
            line-height: 23px;
            background: #00c25f;
            color: #fff;
            text-align: center;
            margin-right: 8px;
            font-size: 13px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
          }
          .jz {
            background: #ff8450;
          }
        }
        .source-language {
          position: absolute;
          top: 11px;
          left: -4px;
          display: flex;
          max-width: 160px;
          flex-direction: column;
          .language-content {
            background: linear-gradient(225deg, #48abff 0%, #1077ff 100%);
            padding: 4px 8px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 18px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          img {
            width: 4px;
            height: 4px;
            min-height: 0;
            margin: 0;
            padding: 0;
          }
        }
      }
    }
  }
  .no_task {
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    color: rgb(16 119 255);
    font-weight: 700;
  }
}
</style>
