<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\App.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 10:59:34
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-04 10:25:15
-->
<template>
  <div id="app" v-on:toggle-dialog="toggleDialogHandle">
    <!-- top -->
    <Top v-if="showTop" v-on:toggle-dialog="toggleDialogHandle"></Top>
    <!-- router-view -->
    <div class="contain">
      <router-view></router-view>
    </div>
    <!-- footer -->
    <Bottom v-if="showBottom"></Bottom>
    <Login v-if="showLogin" v-on:toggle-dialog="toggleDialogHandle"></Login>
    <Regest v-if="showRegist" v-on:toggle-dialog="toggleDialogHandle"></Regest>
    <Resetpwd
      v-if="showResetpwd"
      v-on:toggle-dialog="toggleDialogHandle"
    ></Resetpwd>
  </div>
</template>

<script>
import Top from "@/components/Top.vue";
import Bottom from "@/components/Bottom.vue";
import Login from "@/views/Login.vue";
import Regest from "@/views/Regest.vue";
import Resetpwd from "@/views/Resetpwd.vue";

export default {
  name: "app",
  data: function() {
    return {
      showLogin: false,
      showRegist: false,
      showResetpwd: false,
      showTop: true,
      showBottom: true
    };
  },
  components: {
    Top,
    Bottom,
    Login,
    Regest,
    Resetpwd
  },
  mounted() {
    let routeInfo = this.$route;
    console.log("1");
  },

  watch: {
    $route(to, from) {
      // $logPv({
      //   ptype: to.name,
      //   prev: from.name,
      // });
    }
  },
  methods: {
    /**
     * 自定义弹窗展示、隐藏事件
     * params:{dialogType:Number(1:登录;2:注册;3:重置密码),show:Boolean(true:展示;false:隐藏)}
     */
    toggleDialogHandle(data) {
      let dialogType = data.dialogType;
      let isShow = data.show;
      this.showTop = data.showTop != false;
      this.showBottom = data.showBottom != false;
      this.showLogin = false;
      this.showRegist = false;
      this.showResetpwd = false;
      if (isShow) {
        switch (dialogType) {
          case 1:
            this.showLogin = true;
            break;
          case 2:
            this.showRegist = true;
            break;
          case 3:
            this.showResetpwd = true;
        }
      }
    }
  }
};
</script>

<style lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.contain {
  min-height: 100vh;
}
#app {
  width: 100%;
  background: #fff;

  a {
    text-decoration: none;
  }

  .w {
    width: 970px;
    margin: 0 auto;
    position: relative;
  }
  .w2 {
    width: 970px;
    margin: 0 auto;
    position: relative;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .slh {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }

  .slh2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .slh3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
</style>
