<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\Fyresult.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-22 15:39:50
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-03 11:56:14
-->
<template>
  <div class="fy fy1">
    <div class="content w">
      <div class="title">
        {{ this.$t("fy.reviewRes") }}
        <!-- 未通过展示 -->
        <div
          v-if="resultData.status == 3"
          class="join1"
          @click="$router.push('/review/' + bookId)"
        >
          {{ this.$t("test.startCheck") }}
        </div>
        <div v-else class="join1 disabled">{{ this.$t("test.startCheck") }}</div>
      </div>
      <div class="detail">
        <div class="base">
          <div class="dtitle base_title">{{ this.$t("fy.originTxt") }}</div>
          <div class="dcontent base_content" ref="stopCopy">
            <p
              v-for="(base_c, i) in resultData.originalContent.split('\n')"
              :key="i"
              style="margin-bottom: 12px"
            >
              {{ base_c }}
            </p>
          </div>
        </div>

        <div class="current">
          <div class="dtitle cu_title">{{ this.$t("fy.transTxt") }}</div>
          <div
            class="dcontent base_content"
            v-html="resultData.answerContent"
          ></div>
        </div>
      </div>
      <div class="ftxt" v-if="resultData.translationContent">
        <h3 class="ftxt-title">{{ this.$t("fy.translationRes") }}</h3>
        <div class="ftxt-content">
          <p
            v-for="(base_c, i) in resultData.translationContent.split('\n')"
            :key="i"
            style="margin-bottom: 12px"
            v-html="base_c"
          ></p>
        </div>
      </div>
      <div class="process w">
        <div class="title">{{ this.$t("fy.reviewRes") }}</div>
        <div class="content">
          <el-row :gutter="10" class="mb">
            <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"
              ><div class="sub-title">{{ this.$t("fy.reviewRes") }}</div></el-col
            >
            <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
              <el-select
                disabled
                v-model="resultData.auditStatus"
                :placeholder="$t('fy.pleaseSelect')"
              >
                <el-option :value="1" :label="$t('fy.reviewPass')"></el-option>
                <el-option :value="2" :label="$t('fy.refuse')"></el-option>
              </el-select>
            </el-col>
          </el-row>
          <el-row class="" :gutter="10">
            <el-col :xs="2" :sm="2" :md="2" :lg="2" :xl="2"
              ><div class="sub-title">{{ $t("fy.comment") }}</div></el-col
            >
            <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
              <el-input
                type="textarea"
                :rows="4"
                :disabled="true"
                :placeholder="$t('fy.commentTip')"
                maxlength="255"
                show-word-limit
                v-model="resultData.auditOpinion"
              >
              </el-input>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="judge">
        <div class="title1">{{ this.$t("fy.reviewComment") }}</div>
        <div class="content1">
          <div class="detail1">
            {{ resultData.remark || "" }}
            <!-- 请保持时态一致，句子通顺，旁白统一使用过去时。 -->
          </div>
          <!-- <img src="../assets/testprev/pass.png" alt="" class="icon" /> -->
          <img
            v-if="resultData.status == 3 || resultData.status == 4"
            :src="noPassPng"
            alt=""
            class="icon"
          />
          <img
            v-else
            :src="inPassPng"
            alt=""
            class="icon"
          />
        </div>
      </div>
    </div>
    <div class="warm" v-show="isModel">
      <img
        src="../assets/ic_close.png"
        class="close"
        alt=""
        @click="isModel = false"
      />
      <img src="../assets/ic_warning.png" class="warmImg" alt="" />
      <p>{{ this.$t("fy.noCopy1") }}</p>
      <p>{{ this.$t("fy.noCopy2") }}</p>
    </div>
  </div>
</template>
<script>
import { taskResultApi } from "@/api/task";
import inPass from '@/assets/testprev/in_pass.png';
import noPass from '@/assets/testprev/no_pass.png';
import inPassEn from '@/assets/testprev/in_pass_en.png';
import noPassEn from '@/assets/testprev/no_pass_en.png';

export default {
  name: "ReviewResult",
  data() {
    return {
      bookId: false,
      transContent: "", // 翻译内容
      imageUrl: "",
      formLabelAlign: {
        resume: false, //简历
        years: false,
        certificate: "", // 证书
        words: false, // 单词数
      },
      resultData: {
        no_pass: "../assets/testprev/no_pass.png",
        in_pass: "../assets/testprev/in_pass.png",
        originalContent: "",
        remark: "",
        status: "",
        translationContent: "",
        answerContent: "",
        auditStatus: ""
      },
      isModel: false,
    };
  },
  computed: {
    inPassPng() {
      return this.$i18n.locale === 'cn' ? inPass : inPassEn;
    },
    noPassPng() {
      return this.$i18n.locale === 'cn' ? noPass : noPassEn;
    }
  },
  mounted() {
    this.doStopCopy();
    this.getResult();
  },
  created() {
    this.bookId = this.$route.params.id || false;
    if (!this.bookId) this.$router.push("/");
    this.$nextTick(() => {
      // 禁用右键
      this.$refs.stopCopy.oncontextmenu = new Function(
        "event.returnValue=false"
      );
      // 禁用选择
      this.$refs.stopCopy.onselectstart = new Function(
        "event.returnValue=false"
      );
    });
  },
  methods: {
    doStopCopy() {
      let _this = this;
      this.$nextTick(() => {
        // 禁用右键
        _this.$refs.stopCopy.oncontextmenu = function (e) {
          let event = e || window.event;
          event.returnValue = false;
          _this.isModel = true;
        };
        // 禁用选择
        _this.$refs.stopCopy.onselectstart = function (e) {
          let event = e || window.event;
          event.returnValue = false;
          _this.isModel = true;
        };
        // ctrl + c
        _this.$refs.stopCopy.onkeydown = function (e) {
          let event = e || window.event;
          if (event.ctrlKey && event.keyCode == 67) {
            _this.isModel = true;
            event.returnValue = false;
          }
        };
      });
      document.addEventListener("keydown", function (e) {
        let event = e || window.event;
        if (event.ctrlKey && event.keyCode == 65) {
          _this.isModel = true;
          event.returnValue = false;
        }
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    async getResult() {
      let _this = this;
      let res = await this.$axios({
        url: taskResultApi,
        method: "post",
        data: {
          id: _this.bookId,
        },
      });
      if (res.data.status == 0) {
        Object.assign(_this.resultData, res.data.data);
        if (_this.resultData.status == 2) {
          this.$message.success(this.$t("fy.reviewPass"));
          window.sessionStorage.clear();
          window.localStorage.clear();
          window.setTimeout(() => {
            if (res.data.data.domain) {
              window.location.href = res.data.data.domain;
            }
          }, 1000);
        }
        //  ? this.$router.push("https://www.baidu.com") :'';
      } else {
        this.$message.error(this.$t("fy.reqPageErr"));
      }
    },
  },
};
</script>
<style lang="less" scoped>
.fy1 {
  .warm {
    box-sizing: border-box;
    width: 420px;
    min-height: 152px;
    padding-bottom: 12px;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .warmImg {
      width: 38px;
      height: 38px;
      margin: 0 auto;
      margin-top: 23px;
      margin-bottom: 15px;
    }
    .close {
      width: 19px;
      height: 19px;
      position: absolute;
      top: 11px;
      right: 19px;
    }
    p {
      text-align: center;
      font-size: 15px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 27px;
    }
  }
}
.fy1 {
  padding: 21px 0;
  .content {
    padding-top: 6px;
    .title {
      height: 24px;
      font-size: 24px;
      font-weight: bold;
      color: #1077ff;
      line-height: 20px;
      margin-bottom: 26px;
      border-left: 5px solid #1077ff;
      padding-left: 10px;
      position: relative;
      .join1 {
        position: absolute;
        // width: 160px;
        height: 48px;
        padding: 0 42px;
        line-height: 48px;
        background: #1077ff;
        border-radius: 5px;
        right: 0;
        top: -10px;
        text-align: center;
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        cursor: pointer;
        &.disabled {
          color: rgba(199, 199, 199, 1);
          background: rgba(233, 233, 233, 1);
          border: none;
          cursor: not-allowed;
        }
      }
    }
    .detail {
      display: flex;
      margin-bottom: 21px;

      .dtitle {
        height: 48px;
        background: #f3faff;
        box-shadow: 0px -1px 0px 0px rgba(232, 245, 255, 1);
        font-size: 19px;
        text-align: center;
        font-weight: 500;
        color: #333333;
        line-height: 48px;
        padding: 0;
      }
      .dcontent {
        height: 600px;
        overflow-y: auto;
        padding: 23px;
        font-size: 15px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: rgb(76, 76, 76);
        line-height: 25px;
        max-width: 535px;
        white-space: pre-wrap;
        -moz-user-select: none; /* Firefox私有属性 */
        -webkit-user-select: none; /* WebKit内核私有属性 */
        -ms-user-select: none; /* IE私有属性(IE10及以后) */
        -khtml-user-select: none; /* KHTML内核私有属性 */
        -o-user-select: none; /* Opera私有属性 */
        user-select: none; /* CSS3属性 */
      }
      .base,
      .current,
      .rightyw {
        // width: 544px;
        flex: 1;
        height: 650px;
        border: 1px solid #e8f5ff;
      }
      .rightyw {
        background: rgba(241, 255, 248, 1);
        .dtitle {
          background: rgba(217, 255, 236, 1);
        }
      }

      .current {
        background: rgba(255, 248, 244, 1);
        height: 650px;
        border: 1px solid #e8f5ff;
        .cu_title {
          background-color: rgba(255, 216, 199, 1);
        }
        .cu_content {
          overflow: hidden;
          padding: 20px 0 0 0;
          border-bottom: 1px solid #e8f5ff;

          textarea.el-textarea__inner {
            width: 100%;
            height: 100%;
            border: none;
          }
        }
      }
    }
    .ftxt {
      .ftxt-title {
        height: 48px;
        background: #f3faff;
        box-shadow: 0 -1px 0 0 #e8f5ff;
        font-size: 19px;
        text-align: center;
        font-weight: 500;
        color: #333;
        line-height: 48px;
        padding: 0;
      }
      .ftxt-content {
        max-height: 600px;
        overflow-y: auto;
        padding: 23px;
        font-size: 15px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #4c4c4c;
        line-height: 25px;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
    }
    .process {
      padding-top: 30px;
      .title {
        height: 24px;
        font-size: 24px;
        font-weight: bold;
        color: #1077ff;
        line-height: 20px;
        margin-bottom: 26px;
        border-left: 5px solid #1077ff;
        padding-left: 10px;
      }
      .content {
        border: 1px solid #e8f5ff;
        padding: 20px;
        border-radius: 5px;
        background: #f8fafb;
        .sub-title {
          height: 40px;
          line-height: 40px;
          position: relative;
          &::after {
            content: "*";
            position: absolute;
            left: -8px;
            top: 0px;
            color: red;
          }
        }
        .mb {
          margin-bottom: 20px;
        }
      }
    }
    .judge {
      position: relative;
      min-height: 270px;
      border: 1px solid #e8f5ff;
      .title1 {
        height: 61px;
        line-height: 61px;
        background: rgba(232, 245, 255, 1);
        font-size: 24px;
        font-weight: bold;
        color: #1077ff;
        padding-left: 32px;
        margin-bottom: 0;
      }
      .content1 {
        min-height: 206px;
        padding: 21px 32px;
        background: #fff;
        font-size: 21px;
        font-weight: 500;
        color: #333333;
        line-height: 29px;
        position: relative;
        padding-right: 264px;
        .icon {
          position: absolute;
          top: 24px;
          right: 107px;
          width: 157px;
          height: 157px;
        }
      }
    }
  }
}
</style>