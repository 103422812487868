<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\Regest.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 19:23:15
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-03 15:25:52
-->
<template>
  <div class="mask_page">
    <div class="centeR">
      <img
        src="../assets/dialog_close.png"
        alt=""
        class="close"
        @click="close"
      />
      <template v-if="unregest">
        <div class="top">{{ $t("login.signUpTitle") }}</div>
        <div class="undone" @keydown.enter="handleRegist">
          <div style="margin: 20px"></div>
          <el-form
            :label-position="labelPosition"
            label-width="0px"
            :rules="rules"
            ref="form"
            :model="form"
            status-icon
          >
            <el-form-item label="" prop="name" class="iconNewYes">
              <el-input
                type="text"
                :placeholder="$t('login.name')"
                v-model="form.name"
                autocomplete="off"
                required
              />
            </el-form-item>
            <el-form-item label="" prop="email" class="iconNewYes">
              <el-input
                type="email"
                autocomplete="off"
                :placeholder="$t('login.email')"
                v-model="form.email"
              />
            </el-form-item>
            <el-form-item label="" prop="pwd" class="pass" style="margin-bottom: 30px;">
              <el-input
                type="password"
                autocomplete="off"
                :placeholder="$t('login.pwd')"
                v-model="form.pwd"
                show-password
              />
            </el-form-item>
            <el-form-item label="" prop="checkPass" class="pass">
              <el-input
                type="password"
                autocomplete="off"
                :placeholder="$t('login.confirmPwd')"
                v-model="form.checkPass"
                suffix-icon="el-icon-my-export"
                show-password
              />
            </el-form-item>
            <!-- 注意：prop与input绑定的值一定要一致，否则验证规则中的value会报undefined，因为value即为绑定的input输入值 -->
            <el-form-item
              label=""
              prop="code"
              class="pass identifyinput iconNewYes"
            >
              <el-input
                type="code"
                autocomplete="off"
                :placeholder="$t('login.code')"
                v-model="form.code"
                class="identifyinput"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <div class="identifybox">
                <div>
                  <img
                    :src="srcUrl"
                    alt="随机验证码图"
                    @load="codeimgLoadding = false"
                  />
                </div>
                <el-button @click="refreshCode" type="text" class="textbtn"
                  >{{ $t('login.codeNew') }}</el-button
                >
              </div>
            </el-form-item>

            <el-form-item>
              <el-button
                :class="[
                  'zhuce',
                  {
                    unready:
                      !nameReady ||
                      !emailReady ||
                      !passReady ||
                      !pass2Ready ||
                      !codeReady
                  }
                ]"
                type="primary"
                @click="handleRegist"
                >{{ $t('signUp') }}</el-button
              >
            </el-form-item>
          </el-form>
          <div class="login_rightnow">
            {{ $t("login.hasAccount") }}
            <span
              class="login"
              @click="$emit('toggle-dialog', { dialogType: 1, show: true })"
              >{{ $t("login.signInNow") }}</span
            >
          </div>
        </div>
      </template>

      <template v-else>
        <div class="top">{{ $t("login.signUpSucTitle") }}</div>
        <div class="done">
          <img src="../assets/login/reg_done.png" alt="" />
          <div class="tip1">{{ $t("login.signUpSuc") }}</div>
          <div class="tip2" v-html="$t('login.signUpSucTip')"></div>
          <div
            class="btn"
            @click="$emit('toggle-dialog', { dialogType: 1, show: true })"
          >
            {{ $t('signIn') }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {
  issbccase,
  testTSZF,
  testSpace,
  testEmail,
  testPWD
} from "../plugins/util";
import { registerApi, refreshCodeApi } from "@/api/home";
export default {
  name: "regest",
  data() {
    var checkName = (rule, value, callback) => {
      if (!value) {
        this.nameReady = false;
        return callback(new Error(this.$t("login.nameEmpty")));
      }
      setTimeout(() => {
        if (testTSZF(value)) {
          this.nameReady = false;
          return callback(new Error(this.$t("login.pwdSpecialWordErr")));
        } else if (value.length > 10 && this.$i18n.locale === "cn") {
          return callback(new Error("最多允许10个字符"));
        }
        // else if (issbccase(value)) {
        //   this.nameReady = false;
        //   return callback(new Error("不允许全角"));
        // }
        else if (testSpace(value)) {
          this.nameReady = false;
          return callback(new Error(this.$t("login.pwdSpacesErr")));
        } else {
          this.nameReady = true;
          callback();
        }
      }, 1000);
    };
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        this.emailReady = false;
        return callback(new Error(this.$t("login.emailEmpty")));
      }
      setTimeout(() => {
        if (!testEmail(value)) {
          this.emailReady = false;
          return callback(new Error(this.$t("login.emailErr")));
        } else {
          this.emailReady = true;
          callback();
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        this.passReady = false;
        callback(new Error(this.$t("login.pwdEmpty")));
      } else if (!testPWD(value)) {
        this.passReady = false;
        callback(new Error(this.$t("login.pwdErr")));
      } else if (testTSZF(value)) {
        this.passReady = false;
        return callback(new Error(this.$t("login.pwdSpecialWordErr")));
      } else if (issbccase(value)) {
        this.passReady = false;
        return callback(new Error(this.$t("login.pwdFullWidthErr")));
      } else if (testSpace(value)) {
        this.passReady = false;
        return callback(new Error(this.$t("login.pwdSpacesErr")));
      } else {
        this.passReady = true;
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        this.pass2Ready = false;
        callback(new Error(this.$t("login.confirmPwdEmpty")));
      } else if (value !== this.form.pwd) {
        this.pass2Ready = false;
        callback(new Error(this.$t("login.confirmPwdErr")));
      } else {
        this.pass2Ready = true;
        callback();
      }
    };

    var checkCode = (rule, value, callback) => {
      if (value && value.length == 4) {
        this.codeReady = true;
      } else {
        this.codeReady = false;
        callback(new Error(this.$t("login.codeErr")));
      }
    };
    return {
      codeimgLoadding: true,
      ajaxing: false,
      unregest: true, // 未注册完成
      labelPosition: "left",
      nameReady: false,
      emailReady: false,
      passReady: false,
      pass2Ready: false,
      codeReady: false,
      form: {
        name: "",
        email: "",
        pwd: "",
        checkPass: "",
        code: "",
        key: ""
      },
      rules: {
        name: [{ validator: checkName, trigger: "change" }],
        email: [{ validator: checkEmail, trigger: "change" }],
        pwd: [{ validator: validatePass, trigger: "change" }],
        checkPass: [{ validator: validatePass2, trigger: "change" }],
        code: [{ validator: checkCode, trigger: "change" }]
      },
      timeStamp: "",
      srcUrl: ""
    };
  },
  mounted() {
    this.refreshCode();
  },
  methods: {
    async handleRegist() {
      if (this.ajaxing) return;
      let _this = this;

      if (
        this.nameReady &&
        this.emailReady &&
        this.passReady &&
        this.pass2Ready
      ) {
        this.ajaxing = true;

        let res = await this.$axios({
          url: registerApi,
          method: "post",
          data: _this.form
        });
        res = res.data;

        if (res.status == 0) {
          this.unregest = false;
        } else {
          this.$message.error(
            res.message ? res.message : `${this.$t("login.signUpFail")}, Code:${res.status}`
          );
        }

        this.ajaxing = false;
      }
    },
    close() {
      this.$emit("toggle-dialog", { dialogType: 2, show: false });
    },
    refreshCode() {
      this.timeStamp = new Date().getTime();
      this.srcUrl = refreshCodeApi + "?key=" + this.timeStamp;
      this.form.key = this.timeStamp;
    }
  }
};
</script>

<style lang="less">
.loadingicon {
  height: 100%;
  margin-left: 80px;
}
.identifybox {
  padding: 0px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    width: 107px;
    height: 40px;
    display: block;
  }
}
.iconstyle {
  color: #409eff;
}
.mask_page {
  overflow: auto;
  width: 100%;
  padding-top: 15px;
  .centeR {
    width: 380px;
    margin: 0 auto;
    border: 1px solid #1077ff;
    padding-bottom: 10px;
    .top {
      width: 380px;
      height: 66px;
      background: #1077ff;
      background: url(../assets/login/mask_banner.png) no-repeat center;
      background-size: 100% 100%;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      line-height: 66px;
      text-align: center;
    }

    .undone {
      text-align: center;
      .el-form-item {
        margin-bottom: 20px;
      }
      .el-input__inner {
        width: 304px;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #8a8e96;
        line-height: 19px;
        border: none;
        border-bottom: 1px solid rgba(234, 235, 236, 1);
        &::placeholder {
          font-size: 13px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #8a8e96;
          line-height: 19px;
        }
      }
      .el-input__suffix {
        right: 40px;
        padding-top: 0px;
      }
      .el-input__icon {
        width: 21px;
        height: 21px;
      }
      .el-icon-view {
        background: url(../assets/login/password_see.png) center no-repeat;
        background-size: cover;
      }
      .el-icon-view:before {
        content: "";
        font-size: 16px;
        visibility: hidden;
      }
      .zhuce {
        width: 427px;
        height: 59px;
        background: #1077ff;
        border-radius: 5px;
        color: #fff;
        font-weight: bold;
        font-size: 21px;

        &.unready {
          background: rgba(233, 233, 233, 1);
          color: rgba(199, 199, 199, 1);
          border: none;
          cursor: not-allowed;
        }
      }

      .login_rightnow {
        width: 427px;
        margin: 0 auto;
        height: 27px;
        font-size: 19px;
        font-weight: 500;
        color: #8a8e96;
        line-height: 27px;
        text-align: left;
        margin-bottom: 12px;
        .login {
          color: rgba(16, 119, 255, 1);
          cursor: pointer;
        }
      }
    }

    .done {
      img {
        display: block;
        width: 133px;
        height: 133px;
        margin: 0 auto;
        margin-top: 64px;
        margin-bottom: 32px;
      }
      .tip1 {
        height: 35px;
        font-size: 24px;
        font-weight: bold;
        color: #1077ff;
        line-height: 35px;
        text-align: center;
        margin-bottom: 21px;
      }
      .tip2 {
        font-size: 19px;
        font-weight: 500;
        color: #8a8e96;
        line-height: 29px;
        text-align: center;
        margin-bottom: 93px;
      }
      .btn {
        cursor: pointer;
        margin: 0 auto;
        width: 427px;
        height: 59px;
        line-height: 59px;
        background: #1077ff;
        border-radius: 5px;
        text-align: center;
        font-size: 21px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .el-form-item__error {
      width: 400px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      font-size: 16px;
      color: #ff0000;
      line-height: 24px;
      word-break: break-word;
    }

    .pass .el-input__validateIcon {
      display: none;
    }
  }
}
.mask_page {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  .centeR {
    width: 380px;
    margin: 140px auto 0;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    position: relative;
    border: 0;
    padding-bottom: 7px;
    .top {
      width: 380px;
      height: 66px;
      background: #1077ff;
      background: url(../assets/login/mask_banner.png) no-repeat center;
      background-size: 100% 100%;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      line-height: 66px;
      text-align: center;
    }
    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 22px;
      top: 25px;
      cursor: pointer;
    }
    .undone {
      .iconNewYes {
        .el-icon-circle-check {
          background: url(../assets/login/yes.png) center no-repeat;
          background-size: cover;
        }
        .el-icon-circle-check:before {
          content: "";
          font-size: 16px;
          visibility: hidden;
        }
        .el-icon-circle-close {
          background: transparent;
          background-size: cover;
        }
        .el-icon-circle-close:before {
          content: "";
          font-size: 16px;
          visibility: hidden;
        }
        .el-input__icon {
          line-height: 21px;
        }
      }
      text-align: center;
      .el-form-item {
        margin-bottom: 18px;
      }
      .el-input__inner {
        width: 304px;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #8a8e96;
        line-height: 19px;
        border: none;
        border-bottom: 1px solid rgba(234, 235, 236, 1);
        &::placeholder {
          font-size: 13px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #8a8e96;
          line-height: 19px;
        }
      }
      .el-input__suffix {
        right: 40px;
      }
      .pass {
        .el-input__suffix {
          padding-top: 7px;
        }
        .el-icon-view {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACuUlEQVRYR+2WTUgUYRjH/8+7ibnSJuTJToXQBx2i8FAZ1WUjIiuErUuUYszuVriz7M41oUvsbM6KtMNE6aVDilBRlHbJIjtJpySIIBA0CAUxWj9y54ntC3edGcfU7LDv9X0+fu/z+RLW+NAa+0cRoBiBJUUgoekHiBEA8R4wqhlUkStiAk+A8AFMb5jQrcihAbfFvSiApnVWzGGmkWE2gbHDlWHCO4K4vQ6lHbLcMOGk4wiQTOlHTeYOMKpcOS4QIqJPLKhRaQ722ulbAhiG4Z38aqoMDts6/vFKes9gJlA1M++ydUKk+7wiJklSplBmAUBrd3dZdmTsGTNqrQwS8BoekuLNobfz71Op9LZZEwaAQ5Z6RAM+r/AXQuQBGIZRMpkxHzLzMRsjLbFI8BoRmcmUUQvTrIGAEMIzKF+5+DKnk2zTFTZx3Qa+11fuqZMk6dvv+zyAhJa+B8YZ61DSEyUaOt7efqdqem6mkxn++XJE1F9C4nwkIg2rrXoPg+st7RC6FDl8dgFAUjNOmZy9b0M+xaVlW+PhC5+TKf2FXXpANFize/u+oaHhjZnZzEeAN1jZE+Q5HZOlBz9b+NdRNf05Mx+2Dj0exeVwXSKl18PkHse2EuJcPBK8q2p6FzMHbFLZH5dDR/IAElp6DIxN1hEQajwaVFQtrTIj5gQgSNyMycHLqqa3MPNVmzSMK3K48v8CcEoBQI+VaOjEqqbAqQgBmkbp+i2rWoS5fKxpG+YAVn0QET31ecVJ20GUg8iN4rmR8T4wH1zZUYxXns2V/mggMJU3wKyc5JbRlwwnTDYv2bbcUpYRKO0rF3FXy2i+w0Sr4QdlO/92HYMwKogaY5FQn91DXH1IsjzdwEATg3c6DaE/dyv1ISl0dqPt1v6smQ0QeO8/+5K5evEyhBZNwTJsu1ItAhQj8B3ljoowKP4aPQAAAABJRU5ErkJggg==)
            center no-repeat;
          background-size: cover;
        }
        .el-icon-view:before {
          content: "";
          font-size: 16px;
          visibility: hidden;
        }
      }
      .zhuce {
        width: 304px;
        height: 42px;
        background: #1077ff;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;

        &.unready {
          background: rgba(233, 233, 233, 1);
          color: rgba(199, 199, 199, 1);
          border: none;
          cursor: not-allowed;
        }
      }

      .reset_pwd {
        width: 304px;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #8a8e96;
        line-height: 19px;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: right;
        margin-bottom: 50px;
        span {
          font-size: 13px;
        }
        span.s2 {
          color: rgba(16, 119, 255, 1);
          font-size: 13px;
          cursor: pointer;
        }
      }

      .login_rightnow {
        width: 304px;
        margin: 0 auto;
        height: 42px;
        font-size: 13px;
        font-weight: 500;
        color: #8a8e96;
        line-height: 27px;
        text-align: left;
        margin-bottom: 12px;
        .login {
          color: rgba(16, 119, 255, 1);
          cursor: pointer;
        }
      }
    }

    .done {
      img {
        display: block;
        width: 95px;
        height: 95px;
        margin: 0 auto;
        margin-top: 46px;
        margin-bottom: 23px;
      }
      .tip1 {
        height: auto;
        font-size: 17px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #1077ff;
        line-height: 25px;
        text-align: center;
        margin-bottom: 15px;
      }
      .tip2 {
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #8a8e96;
        line-height: 21px;
        text-align: center;
        margin-bottom: 65px;
      }
      .btn {
        cursor: pointer;
        margin: 0 auto;
        width: 304px;
        height: 42px;
        line-height: 42px;
        background: #1077ff;
        border-radius: 4px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 72px;
      }
    }

    .el-form-item__error {
      width: 298px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      font-size: 13px;
      color: #ff0000;
      line-height: 14px;
      word-break: break-word;
    }

    .pass .el-input__validateIcon {
      display: none;
    }
  }
}
</style>
