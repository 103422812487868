//获取url参数
export function getQueryString(url, name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return "";
}

// 删除url中的参数
export function delQueryString(url, ref) {
  let str = "";
  if (url.indexOf("?") != -1) {
    str = url.substr(url.indexOf("?") + 1);
  } else {
    return url;
  }
  let arr = "";
  let returnUrl = "";
  if (str.indexOf("&") != -1) {
    arr = str.split("&");
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].split("=")[0] != ref) {
        returnUrl = returnUrl + arr[i].split("=")[0] + "=" + arr[i].split("=")[1] + "&";
      }
    }
    return url.substring(0, url.indexOf("?")) + "?" + returnUrl.substring(0, returnUrl.length - 1);
  } else {
    arr = str.split("=");
    if (arr[0] == ref) {
      return url.substring(0, url.indexOf("?"));
    } else {
      return url;
    }
  }
}