/*
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\main.js
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 10:59:34
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-01-25 18:12:54
 */
import Vue from "vue";
import i18n from "./i18n/i18n.js";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
// import "./plugins/ant-design-vue.js";
import "./plugins/element.js";
// import { logPvFun, logClickFun, logEventFun } from './plugins/log.js'
// window.$logPv = logPvFun
// window.$logClick = logClickFun
// window.$logEvent = logEventFun

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount("#app");
