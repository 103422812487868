<template>
    <div class="userInfoPrivacy">
        <div class="w2 privacy-div">
            <h2>Storytrans用户个人信息保护及隐私政策协议</h2>
            <h3>版本发布日期：2022年9月28日</h3>
            <p>
                Storytrans.com（以下简称“Storytrans”或“我们”）深知个人信息对Storytrans平台用户（以下简称“用户”或“您”）而言的重要性，也感谢您对我们的信任。《Storytrans用户个人信息保护及隐私政策协议》（以下简称“本协议”）与您使用我们的相关产品/服务关系紧密，我们建议您仔细阅读并理解本协议全部内容，做出您认为适当的选择。一旦您使用或继续使用我们的产品/服务时，即意味着您已经完全理解并无条件接受本协议中的所有内容。如果您不同意本协议，请停止访问Storytrans，并停止使用我们的产品和服务。
            </p>

            <h4>一、数据收集与使用</h4>
            <p>
                1. 为了帮助您在Storytrans创建帐户并使用我们的平台，我们需要收集和处理一些个人信息。 “个人信息”是可以识别您身份并向您提供Storytrans上相关服务的任何信息。这可能包括但不限于您的个人信息（如真实姓名、性别、年龄、有效身份证件信息、个人经历等）、联系方式（如手机号码、电子邮箱、社交媒体账号等）、测试或认证结果、互联网协议（“IP”）地址、支付信息（如收款方式、银行卡信息、交易记录等）及其他您在Storytrans填写的信息。您已了解并同意我们使用您的个人信息来：
            </p>
            <p>（1）创建您的 Storytrans 帐户并为您提供我们的服务；</p>
            <p>（2）在整个平台上识别您；</p>
            <p>（3）向您提供平台上的内容；</p>
            <p>（4）为您提供技术支持服务；</p>
            <p>（5）与您沟通（例如，让您了解即将发生的变化或改进）；</p>
            <p>（6）对您进行认证从而令您有在平台上领取翻译或审校任务的权限；</p>
            <p>（7）根据您的特长向您推荐合适的任务；</p>
            <p>（8）根据您已完成的任务向您进行支付；</p>
            <p>（9）计算匿名和汇总统计数据，以更好地了解我们的用户、改进我们的服务、开发新功能、个性化和定制您的故事发现体验；</p>
            <p>（10）帮助防止垃圾邮件、欺诈、滥用和其他被禁止的活动；</p>
            <p>（11）出于安全目的，其他目的旨在运营、开发和改进我们的服务。</p>
            <p>
                2. 其中上述个人信息中的身份证件号码、个人生物识别信息、银行或其他资金账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童的个人信息为个人敏感信息。
            </p>
            <p>
                3. 若你提供的信息中含有其他用户的个人信息，在向我们提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策第八条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。
            </p>
            <h4>二、信息共享</h4>
            <p>
            1. 除了您个人简介性质的信息（如头像、昵称、性别、生日、擅长语种、所在地、个人简介等）以外，我们默认将您的其他个人信息视为隐私，因此不会在平台上显示（除非您通过账户设置选择公开显示适用的信息）或向其他Storytrans用户透露（已被匿名化的信息和数据除外）。
            </p>
            <p>2. 我们不会将您的个人识别信息出租或出售给其他公司或个人，除非我们征得您的同意，但以下非常有限的情况除外：</p>
            <p>（1）遵守适用法律（包括任何犯罪调查）、法规、法律程序或可执行的政府要求；</p>
            <p>（2）执行或应用我们的使用条款和其他政策，包括调查潜在违规行为；</p>
            <p>（3）保护Storytrans、Storytrans的员工和用户或公众的权利、财产或安全；</p>
            <p>（4）在必要的范围内向关联公司提供，以便关联方与我们开展业务合作。</p>

            <h4>三、信息安全</h4>
            <p>1. 我们不断实施和更新物理、组织、合同和技术安全措施，以保护您的个人信息免遭丢失、被盗、未经授权的访问、披露、复制、使用或修改。唯一有权获取您的个人信息的员工是那些在业务上有必要获取的员工或其他依照其职责合理地需要此类信息的员工。在所有 Storytrans平台上，我们支持对所有传输进行加密，以保护在我们平台上提交的个人信息。</p>

            <h4>四、非个人信息</h4>
            <p>1. Storytrans在我们的平台上使用 cookie和类似技术来收集无法识别您个人身份的信息（“非个人信息”），以帮助我们向您提供我们的服务并了解我们的服务是如何使用的。我们还使用这些技术来帮助传递有关Storytrans产品和服务的促销信息和广告。多数Web浏览器会自动接受 Cookie，但您通常可根据自己的需要和浏览器的功能来修改浏览器的设置以拒绝 Cookie。</p>
            <p>2. 我们可能会对非个人信息进行使用，并与第三方（如我们的合作方）共享。例如，我们可能会向我们的合作方展示我们平台的一般使用趋势。我们对这些非个人信息的处理无法令任何第三方识别到您。</p>

            <h4>五、数据保存</h4>
            <p>1.如我们基于向您提供部分服务或功能的需要将您的个人信息传输至境外，我们会事先获得您的明确授权同意，并严格按照适用法律法规的规定执行；针对以上情形，我们会确保依据本政策对您的个人信息提供足够的保护。</p>
            <p>2.我们只会在达成本政策所述目的所需的最短期限内保留您的个人信息，除非法律法规有强制的存留要求。在您的个人信息超出保留期限后，我们会根据适用法律的要求删除或者匿名化处理您的个人信息。</p>
            <p>3.如本平台服务决定停止运营时，我们将在相关服务停止运营后停止继续收集您的个人信息。我们将以公告形式或邮件形式将停止运营通知向您送达。对已持有您的个人信息，我们将进行删除或匿名化处理，除非法律法规另有规定。涉及儿童个人信息的，我们会采取必要合理方式及时将停止运营的通知及时告知儿童监护人。</p>

            <h4>六、个人权利请求</h4>
            <p>1. 在某些情况下，您有权要求我们满足以下一个或多个请求。在满足您的请求之前，我们可能要求您提供身份证明或其他信息。 </p>
            <p>（1）查询和更正权：您可以要求我们提供我们持有的您的信息详情以及您的信息的副本，还可以申请更正任何信息错误。 </p>
            <p>（2）删除权（“被遗忘权”）：在某些情形下，要求删除您的信息的权利。在某些情形下，有些类型的信息我们可能无法删除，特别是若我们有保存这些信息的法定义务（如出于监管报告的目的）或者您想让我们继续向您提供产品而且信息处理是提供产品所必需的。 </p>
            <p>（3）撤销同意权：对于我们已获得您同意的除存储外（存储请参考上一款约定）的任何信息处理，您可以随时撤销同意。如您撤销同意我们对您一些信息的处理，可能造成您与我们在《Storytrans服务协议》等双方达成的其他协议（以下简称“其他协议”）项下的权利无法实现和/或义务无法承担（例如您撤销同意我们使用您的支付信息，可能造成我们无法就您已经完成的任务进行支付）。您已完全了解并同意，如由此造成您违反与我们达成的本协议或其他协议项下的约定的，您需要承担相应的违约责任；如由此造成我们难以实现本协议或其他协议项下的义务的，我们无需承担任何责任。</p>
            <p>2. 我们将会根据个案实际情况来评估任何权利行使请求。在某些情形下，根据适用的数据保护法规中的相关豁免规定，我们可能没有满足这些请求的法律义务。在某些情形下，这可能意味着即使您撤销同意，我们也可以保存您的信息。如需行使这些权利，请您与我们进行联系。 </p>

            <h4>七、隐私政策的变更</h4>
            <p>Storytrans保留随时更改本隐私政策的权利，任何更改将在发布后立即生效，恕不另行通知。我们鼓励您定期查看和审阅本协议，了解最新内容，如您不同意本协议和/或修订后的本协议，应停止使用我们的服务。</p>

            <h4>八、联系我们</h4>
            <p>如您对本政策内容有任何疑问、意见或建议，您可以可以通过以下方式与我们联系：</p>
            <p>电子邮箱：inknettranslation@gmail.com</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UserInfoPrivacy',
    mounted () {
        this.$root.$children[0].toggleDialogHandle({
          showTop: false,
          showBottom: false
        });
    },
    destroyed () {
        this.$root.$children[0].toggleDialogHandle({
          showTop: true,
          showBottom: true
        });
    }
}
</script>

<style lang="less" scoped>
#app {
    .userInfoPrivacy {
        .privacy-div {
            margin-top: 20px;
            h2 {
                margin: 10px 0;
            }
            h3 {
                margin-bottom: 16px;
            }
            h4 {
                margin-top: 16px;
            }
            p {
                margin: 8px 0;
            }
        }
    }
}
</style>