var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top"},[_c('div',{staticClass:"w clearfix"},[_c('div',{staticClass:"logo fl"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"log",attrs:{"src":_vm.logoPng,"alt":""}})])]),(_vm.isErrPage)?_c('div',[(
          _vm.$route.path.includes('login') ||
            _vm.$route.path.includes('register') ||
            _vm.$route.path.includes('resetpwd')
        )?_c('div',{staticClass:"login_reg_welcome fl"},[_vm._v(" 欢迎注册Storytrans，期待你的加入 ")]):_vm._e(),(
          !_vm.logined &&
            !(
              _vm.$route.path.includes('login') ||
              _vm.$route.path.includes('register') ||
              _vm.$route.path.includes('resetpwd')
            )
        )?_c('div',{staticClass:"login fr"},[_c('div',{staticClass:"btn lg",on:{"click":function($event){return _vm.$emit('toggle-dialog', { dialogType: 1, show: true })}}},[_vm._v(" "+_vm._s(_vm.$t("signIn"))+" ")]),_c('div',{staticClass:"btn zc",on:{"click":function($event){return _vm.$emit('toggle-dialog', { dialogType: 2, show: true })}}},[_vm._v(" "+_vm._s(_vm.$t("signUp"))+" ")])]):_vm._e(),(_vm.logined)?_c('div',{staticClass:"logined fr"},[_c('div',{staticClass:"username"},[_vm._v("Hi, "+_vm._s(_vm.userInfo.name || "尊敬的用户"))]),_c('div',{staticClass:"line"},[_vm._v("|")]),_c('div',{staticClass:"logout",on:{"click":_vm.handleLogout}},[_vm._v(_vm._s(_vm.$t("logout")))])]):_vm._e(),_c('div',{staticClass:"lang-drop fr"},[_c('el-dropdown',{attrs:{"trigger":"click"},on:{"command":_vm.handleLangChange}},[_c('span',{staticClass:"lang-drop-link"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === "cn" ? "中文" : "English")+" "),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"command":"cn"}},[_vm._v("中文")]),_c('el-dropdown-item',{attrs:{"command":"en"}},[_vm._v("English")])],1)],1)],1),_c('div',{staticClass:"login"},[(_vm.$route.path.includes('qa'))?_c('div',{staticClass:"back_home fr btn zc",on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" "+_vm._s(_vm.$t("backHome"))+" ")]):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }