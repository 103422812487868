<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\components\Bottom.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 15:48:16
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-03 14:55:54
-->
<template>
  <div class="footer">
    <div class="w">
      {{ $t("contactUs") }}
      <a @click="$router.push(userAgreementUrl)">{{ agreement.userAgreement }}</a>
      <a @click="$router.push(serviceAgreementUrl)">{{ agreement.serviceAgreement }}</a>
    </div>
  </div>
</template>
<script>
import i18n from "@/i18n/i18n";

export default {
  name: "bottom",
  data() {
    return {
      settlementCurrency: "",
    };
  },

  computed: {
    agreement() {
      // 获取结算币种
      const settlementCurrency = window.sessionStorage.getItem("settlementCurrency");
      if (!settlementCurrency) {
        return {
          userAgreement: this.$t("userAgreement"),
          serviceAgreement: this.$t("serviceAgreement"),
        };
      }

      // 1 人民币  2 其他
      if (+settlementCurrency === 1) {
        return {
          userAgreement: "《Storytrans用户个人信息保护及隐私政策协议》",
          serviceAgreement: "《Storytrans服务协议》",
        };
      } else {
        return {
          userAgreement: "Storytrans Privacy Policy Agreement",
          serviceAgreement:  "Storytrans Service Agreement",
        };
      }
    },

    userAgreementUrl() {
      const settlementCurrency = window.sessionStorage.getItem("settlementCurrency");
      if (!settlementCurrency) {
        return this.$i18n.locale === "cn" ? "/userinfoprivacy" : "/userinfoprivacy_en";
      }

      // 1 人民币  2 其他
      if (+settlementCurrency === 1) {
        return "/userinfoprivacy"
      } else {
        return "/userinfoprivacy_en"
      }
    },

    serviceAgreementUrl() {
      const settlementCurrency = window.sessionStorage.getItem("settlementCurrency");
      if (!settlementCurrency) {
        return this.$i18n.locale === "cn" ? "/serviceagreement" : "/serviceagreement_en";
      }

      // 1 人民币  2 其他
      if (+settlementCurrency === 1) {
        return "/serviceagreement"
      } else {
        return "/serviceagreement_en"
      }
    },
  },

  mounted() {
    // 获取结算币种
    this.settlementCurrency = window.sessionStorage.getItem("settlementCurrency");
  },
};
</script>
<style lang="less" scoped>
.footer {
  text-align: center;
  width: 100%;
  font-size: 11px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  line-height: 23px;
  background-color: rgba(35, 35, 35, 1);
  .w {
    padding: 36px 0;
    height: 100%;
    background-color: rgba(35, 35, 35, 1);
    a {
      margin-left: 16px;
      cursor: pointer;
    }
  }
}
</style>
