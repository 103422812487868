<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\Resetpwd.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 19:23:15
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-03 13:38:16
-->
<template>
  <div class="mask_page">
    <div class="center">
      <img
        src="../assets/dialog_close.png"
        alt=""
        class="close"
        @click="close"
      />
      <template v-if="unregest">
        <div class="top">{{ $t("login.findPwd") }}</div>
        <div class="undone" @keydown.enter="handleResetPwd">
          <div style="margin: 20px"></div>
          <el-form
            :label-position="labelPosition"
            label-width="0px"
            :rules="rules"
            ref="form"
            :model="form"
            status-icon
          >
            <el-form-item label="" prop="email" class="iconNewYes">
              <el-input
                :placeholder="$t('login.email')"
                type="email"
                autocomplete="off"
                v-model="form.email"
              />
            </el-form-item>

            <el-form-item label="" prop="yzm" class="yzm">
              <el-input
                type="text"
                :placeholder="$t('login.codeRest')"
                v-model="form.yzm"
                autocomplete="off"
                required
              />
              <div
                :class="['yzm_btn', { disable: timelong != 60 }]"
                @click="handleYZMDJS"
              >
                {{ yzmContent }}
              </div>
            </el-form-item>

            <el-form-item label="" prop="pass" class="pass">
              <el-input
                type="password"
                autocomplete="off"
                :placeholder="$t('login.pwd')"
                v-model="form.pass"
                show-password
              />
            </el-form-item>
            <el-form-item style="margin-top: 40px;">
              <el-button
                :class="[
                  'zhuce',
                  'cz',
                  { unready: !emailReady || !yzmReady || !passReady }
                ]"
                type="primary"
                @click="handleResetPwd"
                >{{ $t("login.modify") }}</el-button
              >
            </el-form-item>
          </el-form>
          <div class="login_rightnow">
            {{ $t("login.noAccount") }}
            <span
              class="login"
              @click="$emit('toggle-dialog', { dialogType: 2, show: true })"
              >{{ $t("login.signUpNow") }}</span
            >
          </div>
        </div>
      </template>

      <template v-else>
        <div class="top">{{ $t("login.signUpSucTitle") }}</div>
        <div class="done">
          <img src="../assets/login/reg_done.png" alt="" />
          <div class="tip1">{{ $t("login.signUpSuc") }}</div>
          <div class="tip2" v-html="$t('login.signUpSucTip')"></div>
          <div class="btn">{{ $t("signIn") }}</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import {
  issbccase,
  testTSZF,
  testSpace,
  testEmail,
  testPWD
} from "../plugins/util";
import { resetPwdApi, yzmApi } from "@/api/home";
export default {
  name: "resetpwd",
  data() {
    var checkEmail = (rule, value, callback) => {
      if (!value) {
        this.emailReady = false;
        return callback(new Error(this.$t("login.emailEmpty")));
      }
      setTimeout(() => {
        if (!testEmail(value)) {
          this.emailReady = false;
          return callback(new Error(this.$t("login.emailErr")));
        } else {
          this.emailReady = true;
          callback();
        }
      }, 1000);
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        this.passReady = false;
        callback(new Error(this.$t("login.pwdEmpty")));
      } else if (!testPWD(value)) {
        this.passReady = false;
        callback(new Error(this.$t("login.pwdErr")));
      } else if (testTSZF(value)) {
        this.passReady = false;
        return callback(new Error(this.$t("login.pwdSpecialWordErr")));
      } else if (issbccase(value)) {
        this.passReady = false;
        return callback(new Error(this.$t("login.pwdFullWidthErr")));
      } else if (testSpace(value)) {
        this.passReady = false;
        return callback(new Error(this.$t("login.pwdSpacesErr")));
      } else {
        this.passReady = true;
        callback();
      }
    };
    var checkYzm = (rule, value, callback) => {
      if (!value) {
        this.yzmReady = false;
        return callback(new Error(this.$t("login.codeEmpty")));
      }
      setTimeout(() => {
        if (!(value.length == 6)) {
          this.yzmReady = false;
          return callback(new Error(this.$t("login.codeErrRest")));
        } else {
          this.yzmReady = true;
          callback();
        }
      }, 1000);
    };
    return {
      ajaxing: false,
      unregest: true, // 未注册完成
      labelPosition: "left",
      emailReady: false,
      yzmReady: false,
      passReady: false,
      yzmContent: this.$t("login.getCode"),
      timelong: 60,
      form: {
        email: "",
        yzm: "",
        pass: ""
      },
      rules: {
        email: [{ validator: checkEmail, trigger: "change" }],
        yzm: [{ validator: checkYzm, trigger: "change" }],
        pass: [{ validator: validatePass, trigger: "change" }]
      }
    };
  },
  methods: {
    // 点击获取验证码
    handleYZMDJS() {
      if (!this.form.email) {
        this.$message.error(this.$t("login.emailEmpty"));
        return;
      }

      if (window.yzmTimeid) return;
      this.yzmContent = this.timelong;
      window.yzmTimeid = window.setInterval(() => {
        if (this.timelong <= 0) {
          window.clearInterval(window.yzmTimeid);
          this.timelong = 60;
          window.yzmTimeid = false;
          this.yzmContent = this.$t("login.getCode");
        } else {
          this.timelong -= 1;
          this.yzmContent = this.timelong;
        }
      }, 1000);

      this.handleGetYZM();
    },
    // 验证码请求
    async handleGetYZM() {
      // 请求验证码
      let res = await this.$axios({
        url: yzmApi,
        method: "post",
        data: {
          email: this.form.email
        }
      });

      res = res.data;
      if (res.status == 0) {
        this.$message.success(this.$t("login.codeToEmail"));
      } else {
        this.$message.error(
          res.message ? res.message : `${this.$t("login.getCodeErr")}; Code: ${res.status}`
        );
      }
    },

    // 重置请求
    async handleResetPwd() {
      if (this.ajaxing) return;

      if (this.emailReady && this.yzmReady && this.passReady) {
        this.ajaxing = true;
        // 提交请求
        let _this = this;

        let res = await _this.$axios({
          url: resetPwdApi,
          method: "post",
          data: {
            checkCode: _this.form.yzm,
            email: _this.form.email,
            pwd: _this.form.pass
          }
        });

        res = res.data;

        if (res.status == 0) {
          window.sessionStorage.clear();
          this.$message.success(this.$t("login.resetPwdSuc"));
          window.setTimeout(() => {
            this.$emit("toggle-dialog", { dialogType: 1, show: true });
          }, 1000);
        } else {
          this.$message.error(
            res.message ? res.message : `${this.$t("netErr")}, Code: ${res.status}`
          );
        }

        // 请求结束
        this.ajaxing = false;
      }
    },
    close() {
      this.$emit("toggle-dialog", { dialogType: 3, show: false });
    }
  }
};
</script>

<style lang="less">
.mask_page {
  overflow: auto;
  width: 100%;
  padding-top: 15px;
  .center {
    width: 380px;
    // height: 691px;
    margin: 0 auto;
    border: 1px solid #1077ff;
    .close {
      width: 16px;
      height: 16px;
      position: absolute;
      right: 22px;
      top: 25px;
      cursor: pointer;
    }
    .top {
      width: 380px;
      height: 66px;
      background: #1077ff;
      background: url(../assets/login/mask_banner.png) no-repeat center;
      background-size: 100% 100%;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      line-height: 66px;
      text-align: center;
    }

    .undone {
      text-align: center;
      .el-form-item {
        margin-bottom: 20px;
      }
      .el-input__inner {
        width: 304px;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #8a8e96;
        line-height: 19px;
        border: none;
        border-bottom: 1px solid rgba(234, 235, 236, 1);
        &::placeholder {
          font-size: 13px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #8a8e96;
          line-height: 19px;
        }
      }
      .el-input__suffix {
        right: 60px;
        padding-top: 22px;
      }
      .el-input__icon {
        width: 21px;
        height: 21px;
      }
      .el-icon-view {
        background: url(../assets/login/password_see.png) center no-repeat;
        background-size: cover;
      }
      .el-icon-view:before {
        content: "";
        font-size: 16px;
        visibility: hidden;
      }
      .zhuce {
        width: 304px;
        height: 42px;
        // line-height: 42px;
        background: #1077ff;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;

        &.unready {
          background: rgba(233, 233, 233, 1);
          color: rgba(199, 199, 199, 1);
          border: none;
          cursor: not-allowed;
        }
      }

      .login_rightnow {
        width: 427px;
        margin: 0 auto;
        height: 27px;
        font-size: 19px;
        font-weight: 500;
        color: #8a8e96;
        line-height: 27px;
        text-align: left;
        margin-bottom: 12px;
        .login {
          color: rgba(16, 119, 255, 1);
          cursor: pointer;
        }
      }
    }

    .done {
      img {
        display: block;
        width: 133px;
        height: 133px;
        margin: 0 auto;
        margin-top: 64px;
        margin-bottom: 32px;
      }
      .tip1 {
        height: 35px;
        font-size: 24px;
        font-weight: bold;
        color: #1077ff;
        line-height: 35px;
        text-align: center;
        margin-bottom: 21px;
      }
      .tip2 {
        font-size: 19px;
        font-weight: 500;
        color: #8a8e96;
        line-height: 29px;
        text-align: center;
        margin-bottom: 93px;
      }
      .btn {
        cursor: pointer;
        margin: 0 auto;
        width: 427px;
        height: 59px;
        line-height: 59px;
        background: #1077ff;
        border-radius: 5px;
        text-align: center;
        font-size: 21px;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 12px;
      }
    }

    .el-form-item__error {
      width: 400px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      font-size: 16px;
      color: #ff0000;
      line-height: 24px;
      word-break: break-word;
    }

    .pass .el-input__validateIcon {
      display: none;
    }
    .yzm {
      position: relative;
      .yzm_btn {
        position: absolute;
        height: 39px;
        font-size: 12px;
        font-weight: 500;
        color: rgb(16, 119, 255);
        line-height: 39px;
        top: -2px;
        right: 24px;
        background: #fff;
        overflow: hidden;
        cursor: pointer;
        padding: 0 14px;
        &.disable {
          cursor: not-allowed;
        }
      }
    }
  }
}

.mask_page {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  .center {
    width: 380px;
    margin: 140px auto 0;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
    background: #ffffff;
    position: relative;
    border: 0;
    padding-bottom: 7px;
    .top {
      width: 380px;
      height: 66px;
      background: #1077ff;
      background: url(../assets/login/mask_banner.png) no-repeat center;
      background-size: 100% 100%;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      line-height: 66px;
      text-align: center;
    }

    .undone {
      .iconNewYes {
        .el-icon-circle-check {
          background: url(../assets/login/yes.png) center no-repeat;
          background-size: cover;
        }
        .el-icon-circle-check:before {
          content: "";
          font-size: 16px;
          visibility: hidden;
        }
        .el-icon-circle-close {
          background: transparent;
          background-size: cover;
        }
        .el-icon-circle-close:before {
          content: "";
          font-size: 16px;
          visibility: hidden;
        }
        .el-input__icon {
          line-height: 21px;
        }
      }
      text-align: center;
      .el-form-item {
        margin-bottom: 18px;
      }
      .el-input__inner {
        width: 304px;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #8a8e96;
        line-height: 19px;
        border: none;
        border-bottom: 1px solid rgba(234, 235, 236, 1);
        &::placeholder {
          font-size: 13px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #8a8e96;
          line-height: 19px;
        }
      }
      .el-input__suffix {
        right: 40px;
        padding-top: 0;
      }
      .el-input__icon {
        width: 21px;
        height: 21px;
      }
      .pass {
        .el-input__suffix {
          padding-top: 7px;
        }
        .el-icon-view {
          background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAACuUlEQVRYR+2WTUgUYRjH/8+7ibnSJuTJToXQBx2i8FAZ1WUjIiuErUuUYszuVriz7M41oUvsbM6KtMNE6aVDilBRlHbJIjtJpySIIBA0CAUxWj9y54ntC3edGcfU7LDv9X0+fu/z+RLW+NAa+0cRoBiBJUUgoekHiBEA8R4wqhlUkStiAk+A8AFMb5jQrcihAbfFvSiApnVWzGGmkWE2gbHDlWHCO4K4vQ6lHbLcMOGk4wiQTOlHTeYOMKpcOS4QIqJPLKhRaQ722ulbAhiG4Z38aqoMDts6/vFKes9gJlA1M++ydUKk+7wiJklSplBmAUBrd3dZdmTsGTNqrQwS8BoekuLNobfz71Op9LZZEwaAQ5Z6RAM+r/AXQuQBGIZRMpkxHzLzMRsjLbFI8BoRmcmUUQvTrIGAEMIzKF+5+DKnk2zTFTZx3Qa+11fuqZMk6dvv+zyAhJa+B8YZ61DSEyUaOt7efqdqem6mkxn++XJE1F9C4nwkIg2rrXoPg+st7RC6FDl8dgFAUjNOmZy9b0M+xaVlW+PhC5+TKf2FXXpANFize/u+oaHhjZnZzEeAN1jZE+Q5HZOlBz9b+NdRNf05Mx+2Dj0exeVwXSKl18PkHse2EuJcPBK8q2p6FzMHbFLZH5dDR/IAElp6DIxN1hEQajwaVFQtrTIj5gQgSNyMycHLqqa3MPNVmzSMK3K48v8CcEoBQI+VaOjEqqbAqQgBmkbp+i2rWoS5fKxpG+YAVn0QET31ecVJ20GUg8iN4rmR8T4wH1zZUYxXns2V/mggMJU3wKyc5JbRlwwnTDYv2bbcUpYRKO0rF3FXy2i+w0Sr4QdlO/92HYMwKogaY5FQn91DXH1IsjzdwEATg3c6DaE/dyv1ISl0dqPt1v6smQ0QeO8/+5K5evEyhBZNwTJsu1ItAhQj8B3ljoowKP4aPQAAAABJRU5ErkJggg==)
            center no-repeat;
          background-size: cover;
        }
        .el-icon-view:before {
          content: "";
          font-size: 16px;
          visibility: hidden;
        }
      }
      .zhuce {
        width: 304px;
        height: 42px;
        background: #1077ff;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #ffffff;

        &.unready {
          background: rgba(233, 233, 233, 1);
          color: rgba(199, 199, 199, 1);
          border: none;
          cursor: not-allowed;
        }
      }

      .reset_pwd {
        width: 304px;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #8a8e96;
        line-height: 19px;
        margin: 0 auto;
        margin-bottom: 20px;
        text-align: right;
        margin-bottom: 50px;
        span {
          font-size: 13px;
        }
        span.s2 {
          color: rgba(16, 119, 255, 1);
          font-size: 13px;
          cursor: pointer;
        }
      }

      .login_rightnow {
        width: 304px;
        margin: 0 auto;
        height: 42px;
        font-size: 13px;
        font-weight: 500;
        color: #8a8e96;
        line-height: 27px;
        text-align: left;
        margin-bottom: 12px;
        .login {
          color: rgba(16, 119, 255, 1);
          cursor: pointer;
        }
      }
    }

    .done {
      img {
        display: block;
        width: 133px;
        height: 133px;
        margin: 0 auto;
        margin-top: 64px;
        margin-bottom: 32px;
      }
      .tip1 {
        height: 35px;
        font-size: 24px;
        font-weight: bold;
        color: #1077ff;
        line-height: 35px;
        text-align: center;
        margin-bottom: 21px;
      }
      .tip2 {
        font-size: 19px;
        font-weight: 500;
        color: #8a8e96;
        line-height: 29px;
        text-align: center;
        margin-bottom: 93px;
      }
      .btn {
        cursor: pointer;
        margin: 0 auto;
        width: 304px;
        height: 59px;
        line-height: 59px;
        background: #1077ff;
        border-radius: 5px;
        text-align: center;
        font-size: 21px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    .el-form-item__error {
      width: 298px;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      font-size: 13px;
      color: #ff0000;
      line-height: 14px;
      word-break: break-word;
    }

    .pass .el-input__validateIcon {
      display: none;
    }
  }
}
</style>
