import Vue from "vue";
import VueI18n from "vue-i18n";
// import Cookies from 'js-cookie'
import cn from "@/i18n/locales/cn";
// import en from "@/i18n/locales/en";

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: "cn",
	messages: {
		cn
	},
	fallbackLocale: "cn",
	silentTranslationWarn: true,
});

const loadedLanguages = ["cn"]

export function loadLanguageAsync(lang) {
	// zh -> cn
	if (lang === "zh") {
		lang = "cn";
	}

	// 如果语言相同
  if (i18n.locale === lang) {
		return Promise.resolve(lang);
	}

  // 如果语言已经加载
  if (loadedLanguages.includes(lang)) {
		i18n.locale = lang;
		return Promise.resolve(lang);
	}

	// 如果尚未加载语言
	return import(/* webpackChunkName: "lang-[request]" */ "@/i18n/locales/" + lang).then((msgs) => {
		i18n.setLocaleMessage(lang, msgs.default);
		i18n.locale = lang;
		loadedLanguages.push(lang)
	});
}

export default i18n;
