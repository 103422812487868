<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\Qa.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-26 10:43:59
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-02 19:27:49
-->
<template>
  <div class="qa">
    <div class="w">
      <img class="banner" :src="bannerPng" alt="" />
      <div class="welcome">
        <div class="top">
          <div class="line1 m5"></div>
          <div class="line2 m21"></div>
          <div class="tip m21">
            <div class="t1">{{ $t("qa.title") }}</div>
            <div class="t2" v-if="$i18n.locale === 'cn'">Introduction</div>
          </div>
          <div class="line2 m5"></div>
          <div class="line1"></div>
        </div>
        <div class="mid">{{ $t("qa.desc") }}</div>
      </div>
      <div class="qas clearfix">
        <div class="left fl">
          <div class="q">{{ $t("qa.q1") }}</div>
          <div class="a">
            <span class="xb">{{ $t("qa.editor") }}:&nbsp;</span
            >{{ $t("qa.a1") }}
          </div>
          <div class="q">{{ $t("qa.q2") }}</div>
          <div class="a">
            <span class="xb">{{ $t("qa.editor") }}:&nbsp;</span>{{ $t("qa.a2") }}
          </div>
          <div class="q">{{ $t("qa.q3") }}</div>
          <div class="a">
            <span class="xb">{{ $t("qa.editor") }}:&nbsp;</span
            >{{ $t("qa.a3") }} <br />
            {{ $t("qa.a3-1") }}
          </div>
        </div>
        <div class="right fr">
          <div class="q">{{ $t("qa.q4") }}</div>
          <div class="a">
            <span class="xb">{{ $t("qa.editor") }}:&nbsp;</span>
            {{ $t("qa.a4") }}<br /><br />
            {{ $t("qa.a4-1") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import banner from "@/assets/logo.png";
import bannerEn from "@/assets/logo_en.jpg";

export default {
  computed: {
    bannerPng() {
      return this.$i18n.locale === "cn" ? banner : bannerEn;
    },
  },
};
</script>
<style lang="less" scoped>
.qa {
  .w {
    .banner {
      width: 100%;
      margin-bottom: 15px;
    }
    .welcome {
      text-align: center;
      margin-bottom: 23px;
      .top {
        display: flex;
        justify-content: center;
        margin-bottom: 11px;
        div {
          display: inline-block;
        }
        .m5 {
          margin-right: 4px;
        }
        .m21 {
          margin-right: 15px;
        }
        .line1 {
          width: 3px;
          height: 38px;
          background: rgba(16, 119, 255, 0.7);
          opacity: 0.1;
        }
        .line2 {
          width: 5px;
          height: 38px;
          background: rgba(16, 119, 255, 0.4);
        }
        .tip {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          height: 38px;
          .t1,
          .t2 {
            display: block;
            text-align: center;
            height: 25px;
            font-size: 17px;
            font-family: PingFang-SC-Bold, PingFang-SC;
            font-weight: bold;
            color: #333333;
            line-height: 25px;
          }
          .t2 {
            height: 21px;
            font-size: 17px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: rgba(51, 51, 51, 0.3);
            line-height: 21px;
          }
        }
      }
      .mid {
        text-align: center;
        height: 19px;
        font-size: 13px;
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #1077ff;
        line-height: 19px;
      }
    }

    .qas {
      margin: 0 auto;
      margin-bottom: 34px;
      .left {
        width: 429px;
      }
      .right {
        width: 411px;
      }
      .q,
      .a {
        width: 100%;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      .q {
        margin-bottom: 8px;
        color: #00c25f;
      }
      .a {
        margin-bottom: 20px;
        .xb {
          color: rgba(16, 119, 255, 1);
        }
      }
    }
  }
}
</style>
