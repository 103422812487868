<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\components\Home\JoinSelect.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 15:53:07
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-04 14:13:21
-->
<template>
  <div>
    <div
      class="join_selcet"
      v-if="taskList && taskList.length && taskList.length > 0"
    >
      <div class="tags">
        <div class="w">
          <div
            class="tag_item"
            :class="{ active: index == activeLangueIndex }"
            v-for="(tag, index) in tags"
            :key="index"
            @click="selectLangueClick(index)"
          >
            {{ tag }}
          </div>
        </div>
      </div>
      <div class="tag_book">
        <div class="w clearfix">
          <div class="tag_book_item fl" v-for="(book, index) in tag_booksItem" :key="index" @click="openTestPrev(book)" >
            <div class="inner">
              <img :src="book.bookImg" alt="" />
              <div class="book_name slh">{{ book.bookName }}</div>
              <!-- 截止时间有可能废弃 -->
              <div class="time slh" style="margin-bottom: 0px">{{ $t("deadline") }}:</div>
              <div class="time slh">{{ book.endTime || "" }}</div>
              <div class="actions">
                <div class="btn" v-if="book.type == 0">{{ $t("home.translation") }}</div>
                <div class="sh btn" v-else-if="book.type == 1">{{ $t("home.check") }}</div>
                <div class="jz btn">{{ $t("home.partTimeJob") }}</div>
              </div>
              <div class="source-language">
                <div class="language-content">
                  {{ $t("home.sourceLang") }}：{{ book.language }}
                </div>
                <img src="../../assets/dog-ear.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="join_selcet"
      v-if="taskList && taskList.length && taskList.length == 0"
    >
      <div class="no_task">暂无任务</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "join_selcet",
  data() {
    return {
      activeLangueIndex: 0,
      tag_booksItem: [],
      tags: [],
      tags: [
        // "英语",
        // "西班牙语",
        // "葡萄牙语",
        // "法语",
        // "德育",
        // "意大利语",
        // "鳄鱼",
        // "日语",
      ],
      tag_books: [
        // {
        //   bookname: "打井人1打井人1打井人1打井人1打井人1",
        //   endtime: "截止时间:2021-02-02",
        //   id: "123",
        // },
        // {
        //   bookname: "打井人2",
        //   endtime: "截止时间:2021-02-0打井人1打井人1打井人1打井人12",
        // },
        // {
        //   bookname: "打井人3",
        //   endtime: "截止时间:2021-02-02",
        // },
        // {
        //   bookname: "打井人3",
        //   endtime: "截止时间:2021-02-02",
        // },
      ],
      langMap: {
        ENGLISH: "英语",
        CHINESE: "中文",
        ENGLISH_TAGALOG: "英语(菲律宾本土语言)",
        SPANISH: "西班牙语",
        NEDERLANDS: "荷兰语",
        DEUTSCH: "德语",
        FRENCH: "法语",
        ITALIANO: "意大利语",
        ROMANIA: "罗马尼亚语",
        FILIPINO: "菲律宾语",
        BAHASA_INDONESIA: "印尼语",
        BAHASA_MELAYU: "马来语",
        VIETNAMESE: "越语",
        ARAB: "阿拉伯语",
        THAI: "泰语",
        KOREAN: "韩语",
        OTHER: "其他语言"
      }
    };
  },
  props: {
    taskList: {
      type: Array,
      default: undefined
    }
  },
  watch: {
    taskList(val) {
      this.tag_books = val;
      this.tag_booksItem = this.tag_books[0]["testTaskVos"] || [];
      let tagsList = [];
      for (let i = 0; i < this.tag_books.length; i++) {
        tagsList.push(this.tag_books[i].targetLanguage);
      }
      this.tags = tagsList;
    }
  },
  methods: {
    openTestPrev(bookInfo) {
      if(bookInfo.type == 0){
        this.$router.push(`/testprev/${bookInfo.id}`);
      } else if(bookInfo.type == 1){
        this.$router.push(`/reviewprev/${bookInfo.id}`);
      }
    },
    selectLangueClick(index) {
      this.activeLangueIndex = index;
      this.tag_booksItem = this.taskList[index].testTaskVos;
    }
  }
};
</script>
<style lang="less">
.join_selcet {
  .tags {
    width: 100%;
    height: 49px;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    .w {
      text-align: center;
      .tag_item {
        padding: 0 4px;
        height: 49px;
        line-height: 49px;
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #666666;
        margin-right: 40px;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          font-size: 15px;
          color: rgba(16, 119, 255, 1);
          font-weight: bold;
          border-bottom: 2px solid rgba(16, 119, 255, 1);
        }
      }
    }
  }
  .tag_book {
    width: 100%;
    padding-top: 20px;
    min-height: 324px;
    .tag_book_item {
      cursor: pointer;
      width: 222px;
      box-sizing: border-box;
      padding: 23px 12px;
      margin-right: 27px;
      background: url(../../assets/book_bg.png) no-repeat center;
      background-size: 100% 100%;
      margin-bottom: 28px;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .inner {
        width: 100%;
        height: 100%;
        padding: 11px;
        background: #fff;
        position: relative;

        img {
          width: 100%;
          min-height: 176px;
          margin-bottom: 8px;
          background: #f1f1f1;
        }

        .book_name {
          width: 100%;
          height: 21px;
          font-size: 15px;
          font-family: PingFang-SC-Heavy, PingFang-SC;
          font-weight: 800;
          color: #333333;
          line-height: 21px;
          margin-bottom: 4px;
        }

        .time {
          height: 17px;
          font-size: 11px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #333333;
          line-height: 17px;
          margin-bottom: 4px;
        }

        .actions {
          .btn {
            display: inline-block;
            // width: 47px;
            padding: 0 10px;
            height: 23px;
            line-height: 23px;
            background: #00c25f;
            color: #fff;
            text-align: center;
            margin-right: 8px;
            font-size: 13px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
          }
          .jz {
            margin-top: 4px;
            background: #ff8450;
          }
          .sh {
            background: #4260f7;
          }
        }
        .source-language {
          position: absolute;
          top: 11px;
          left: -4px;
          display: flex;
          // max-width: 160px;
          flex-direction: column;
          .language-content {
            max-width: 200px;
            background: linear-gradient(225deg, #48abff 0%, #1077ff 100%);
            padding: 4px 8px;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 18px;
          }
          img {
            width: 4px;
            height: 4px;
            min-height: 0;
            margin: 0;
            padding: 0;
            background-color: transparent;
          }
        }
      }
    }
  }
  .no_task {
    text-align: center;
    height: 60px;
    line-height: 60px;
    font-size: 15px;
    color: rgb(16 119 255);
    font-weight: 700;
  }
}
</style>
