<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\components\Home\Banner.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 15:53:07
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-02 19:00:22
-->
<template>
  <div class="banner">
    <div class="w">
      <img :src="bannerPng" alt="" @click="$router.push('/qa')"/>
    </div>
  </div>
</template>
<script>
import banner from "@/assets/logo.png";
import bannerEn from "@/assets/logo_en.jpg";

export default {
  name: "banner",
  computed: {
    bannerPng() {
      return this.$i18n.locale === "cn" ? banner : bannerEn;
    },
  },
};
</script>
<style lang="less">
.banner {
  width: 100%;
  padding: 15px 0;
  background: #fff;
  .w {
    img {
      width: 100%;
      height: 285px;
      cursor: pointer;
    }
  }
}
</style>