/*
 * @Description:
 * @FilePath: \haiwai_zb_pc\src\plugins\axios.js
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 11:09:41
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-04 10:52:19
 */
"use strict";

import Vue from "vue";
import axios from "axios";
import router from "../router/index.js";
import { Loading, Message } from "element-ui";
import i18n from "../i18n/i18n.js";

let loading;
function startLoading() {
  //使用Element loading-start 方法
  loading = Loading.service({
    lock: true,
    text: `${i18n.t("loading")}...`,
    background: "rgba(0,0,0,0.1)",
    fullscreen: false,
    customClass: "ele_loadding"
  });
}
function endLoading() {
  //使用Element loading-close 方法
  loading.close();
}

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    startLoading();
    const token = sessionStorage.getItem("token");
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.token = token; //请求头加上token
    }
    config.headers.lang = i18n.locale === "cn" ? "zh" : "en";
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(res) {
    endLoading();
    // TODO:  401登录失效或需要登录 跳转登录
    if (res.status !== 200) {
      Message.error(
        res.message ? res.message : `${i18n.t("netErr")} ${i18n.t("tryAgain")} Code: ${res.status}`
      );
    }

    if (res.data.status == 401) {
      window.sessionStorage.clear();
      Message.error(i18n.t("login.signInExpire"));
      window.setTimeout(() => {
        router.app.$children[0].toggleDialogHandle({
          dialogType: 1,
          show: true
        });
      }, 2000);
      return res;
    }

    return res;
  },
  function(error) {
    // Do something with response error
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = "请求错误(400)";
          break;
        case 403:
          err.message = "拒绝访问(403)";
          break;
        case 404:
          err.message = "请求出错(404)";
          break;
        case 408:
          err.message = "请求超时(408)";
          break;
        case 500:
          err.message = "服务器错误(500)";
          break;
        case 501:
          err.message = "服务未实现(501)";
          break;
        case 502:
          err.message = "网络错误(502)";
          break;
        case 503:
          err.message = "服务不可用(503)";
          break;
        case 504:
          err.message = "网络超时(504)";
          break;
        case 505:
          err.message = "HTTP版本不受支持(505)";
          break;
        default:
          err.message = `连接出错(${err.response.status})!`;
      }
    } else {
      err.message = "连接服务器失败!";
    }
    Message.error(err.message);
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
