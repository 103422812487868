<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\TestPrev.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-22 14:19:01
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-03-25 17:34:42
-->
<template>
  <div class="test_prev">
    <div class="w2">
      <div class="top clearfix">
        <img class="cover fl" :src="bookInfo.bookImg" alt="" />
        <div class="info fl">
          <div class="book_name">{{ bookInfo.bookName }}</div>
          <div class="etime">{{ $t("deadline") }}: {{ bookInfo.endTime }}</div>
          <div class="req">
            <h3 class="title">
              {{ $t("test.requirementTitle") }}
              <span class="req">{{ $t("test.requirementDesc") }}</span>
            </h3>
            <template v-if="bookInfo.requirement">
              <p class="tip1" v-for="(con , i) in  bookInfo.requirement.split('\n') " :key="i">{{ con }}</p>
            </template>
          </div>
        </div>
        <!-- join:5 提交测试语种被拒绝次数大于2次 join:6 提交测试的语种大于2； -->
        <div class="action" v-if="[1,5,6].includes(bookInfo.join)" @click="process">
          {{ $t("test.startCheck") }}
        </div>
        <div class="action" v-if="bookInfo.join == 2" @click="reviewResult">
          {{ $t("fy.reviewRes") }}
        </div>
        <div class="action disable" v-if="bookInfo.join == 4">{{ $t("test.startCheck") }}</div>
      </div>
      <div class="content">
        <div class="title">{{ $t("test.originTxt") }}</div>
        <div class="detail" ref="stopCopy" v-if="bookInfo.content">
            <p v-for="(con , i) in  bookInfo.content.split('\n') " :key="i">{{ con }}</p>
        </div>
        <!-- <copy-model :isShow="isModel"> </copy-model> -->
      </div>
    </div>
    <div class="warm" v-show="isModel">
        <img src="../assets/ic_close.png" class="close" alt="" @click="isModel = false">
        <img src="../assets/ic_warning.png"  class="warmImg" alt="">
        <p>{{ $t("fy.noCopy1") }}</p>
        <p>{{ $t("fy.noCopy2") }}</p>
    </div>
  </div>
</template>
<script>
import { taskDetail } from "@/api/task";
export default {
  name: "ReviewPrev",
  data() {
    return {
      bookId: false,
      bookInfo: {
        bookName: "",
        endtime: "",
        requirement: "",
        joinUserCount: 0,
        content: "",
        fyPass: false,
        doMain: "",
      },
      isModel:false,
    };
  },
  
  created() {
     
    /* 
      获取页面数据前校验用户登录状态, 未登录直接拉showLoginMask,  如果页面接口响应登录过期直接拉showLoginDissMask
      */
    //  this.showLoginMask();
    // this.showLoginDissMask();

    this.bookId = this.$route.params.id || false;
  },
  mounted() {
    this.getTestData();
  },
  methods: {
    process(){
      if(this.bookInfo.join == 5){
        this.$message.error(this.$t("test.rejectTwice"));
      } else if(this.bookInfo.join == 6){
        this.$message.error(this.$t("test.editLangTwice"));
      } else {
        this.$router.push(`/review/${this.bookId}`);
      }
    },
    doStopCopy(){
      let _this = this 
      this.$nextTick(() => {
        // 禁用右键
        _this.$refs.stopCopy.oncontextmenu = function(e){
          let event  = e || window.event 
          event.returnValue = false
          _this.isModel = true
        }
        // 禁用选择
        _this.$refs.stopCopy.onselectstart = function(e){
          let event  = e || window.event 
          event.returnValue=false
          _this.isModel = true
        }
        // ctrl + c
        _this.$refs.stopCopy.onkeydown = function(e){
          let event  = e || window.event 
          if( event.ctrlKey  && event.keyCode==67 ){
            _this.isModel = true
            event.returnValue=false
          } 
        }
      });
      document.addEventListener('keydown',function(e){
        let event  = e || window.event 
        if( event.ctrlKey  && event.keyCode==65 ){
          _this.isModel = true
          event.returnValue=false
        } 
      })
    },
    reviewResult() {
      if (!this.bookId) {
        this.$router.go("/");
      }
      if (this.fyPass == 2) {
        this.$router.push(this.doMain);
      }
      this.$router.push(`/reviewresult/${this.bookId}`);
    },
    getTestData() {
      let _this = this;
      this.$axios({
        url: taskDetail,
        method: "post",
        data: { id: this.bookId },
      })
        .then((res) => {
          if (res.data.status == 0) {
            _this.bookInfo = res.data.data;
            _this.fyPass = res.data.data.status;
            _this.doMain = res.data.data.domain;
            // _this.bookInfo.join = 2
            // window.content = _this.bookInfo.content;
            _this.doStopCopy();
          } else {
            this.$message.error(this.$t("reqPageErr"));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    showLoginMask() {
      this.$confirm("", {
        confirmButtonText: "立即登录/注册",
        type: "success ",
        center: true,
        showCancelButton: false,
        title: "请先登录/注册 !",
        iconClass: "",
        customClass: "msgbox",
      })
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          return;
        });
    },
    showLoginDissMask() {
      this.$confirm("", {
        confirmButtonText: "登录",
        type: "success ",
        center: true,
        showCancelButton: false,
        title: "登录失效, 请重新登录!",
        iconClass: "",
        customClass: "msgbox",
      })
        .then(() => {
          this.$router.push("/login");
        })
        .catch(() => {
          return;
        });
    },
  },
};
</script>
<style lang="less" scoped>
.test_prev{
.warm{
    box-sizing: border-box;
    width: 420px;
    min-height: 152px;
    padding-bottom: 12px;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 4px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .warmImg{
        width: 38px;
        height: 38px;
        margin: 0 auto;
        margin-top: 23px;
        margin-bottom: 15px;
    }
    .close{
        width: 19px;
        height: 19px;
        position: absolute;
        top: 11px;
        right: 19px;
    }
    p{
        text-align: center;
        font-size: 15px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 27px;
    }

}
}
.test_prev {
  .w2 {
    .top {
      width: 100%;
      // height: 312px;
      background: url(../assets/testprev/bg.png) no-repeat center;
      background-size: 100% 100%;
      padding: 32px;
      position: relative;
      margin: 21px 0;

      .cover {
        width: 187px;
        height: 248px;
        border: 1px solid #cacaca;
        margin-right: 32px;
      }
      .info {
        width: 564px;
        .book_name {
          margin: 10px 0;
          width: 350px;
          font-size: 21px;
          font-weight: 800;
          color: #333333;
          line-height: 29px;
        }
        .etime {
          margin-bottom: 64px;
          height: 27px;
          font-size: 19px;
          font-weight: 500;
          color: rgba(51, 51, 51, 0.7);
          line-height: 27px;
        }
        .req {
          .title {
            border-left: 3px solid #00c25f;
            font-size: 19px;
            font-weight: bold;
            color: #00c25f;
            line-height: 19px;
            margin-bottom: 16px;
            padding-left: 8px;
            .req {
              font-size: 14px;
              color: red;
            }
          }
          .tip1 {
            font-size: 19px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #333333;
            line-height: 29px;
            font-size: 15px;
            margin: 0;
          }
        }
      }
      .action {
        cursor: pointer;
        position: absolute;
        // width: 160px;
        height: 48px;
        padding: 0 42px;
        background: #1077ff;
        border-radius: 5px;
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        line-height: 48px;
        text-align: center;
        top: 43px;
        right: 32px;
        &.disable {
          color: rgba(199, 199, 199, 1);
          background: rgba(233, 233, 233, 1);
          border: none;
          cursor: not-allowed;
        }
      }
      .joined_num {
        position: absolute;
        color: #1077ff;
        top: 100px;
        right: 32px;
        width: 160px;
        text-align: center;
        height: 24px;
        font-size: 16px;
        font-weight: 500;
        color: #1077ff;
        line-height: 24px;
      }
    }

    .content {
      height: 600px;
      overflow-y: auto;
      border: 1px solid #e8f5ff;
      margin-bottom: 32px;
      .title {
        height: 48px;
        line-height: 48px;
        padding-left: 32px;
        background: #f3faff;
        box-shadow: 0px -1px 0px 0px rgba(232, 245, 255, 1);
        font-size: 19px;
        font-weight: 500;
        color: #333333;
      }
      .detail {
        padding: 32px;
        font-size: 20px;
        font-weight: 500;
        color: #4c4c4c;
        line-height: 35px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        -moz-user-select:none; /* Firefox私有属性 */
         -webkit-user-select:none; /* WebKit内核私有属性 */ 
         -ms-user-select:none; /* IE私有属性(IE10及以后) */
          -khtml-user-select:none; /* KHTML内核私有属性 */ 
          -o-user-select:none; /* Opera私有属性 */ 
          user-select:none; /* CSS3属性 */
      }
    }
  }
}
</style>