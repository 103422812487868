<template>
    <div class="serviceAgreement">
        <div class="w2 agreement-div">
            <h2>Storytrans服务协议</h2>
            <h3>版本发布日期：2022年9月28日</h3>

            <p>欢迎使用Storytrans提供的产品和服务。在注册成为“storytrans.com”（以下简称“Storytrans”或“我们”）的正式用户（以下简称“用户”或“您”）并使用我们的产品或服务前，请您务必认真阅读《Storytrans服务协议》（以下简称“本协议”），充分理解各条款内容，特别是免除或限制责任的条款或者其他涉及您重大权益的条款（在本文中将可能以加粗、加下划线等形式提示您重点注意）。如您完成在Storytrans上的注册或使用Storytrans服务，即视为您已阅读并同意本协议的约束。本协议即在您与Storytrans之间产生法律效力，成为对双方均具有约束力的法律文件。除非您已阅读并接受本协议所有条款，否则您无权使用Storytrans服务。</p>
            <p>如果您因未满18周岁等因素而不具有完全民事行为能力，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。</p>
            <p>如果您在中国大陆地区以外使用Storytrans服务，您订立或履行本协议还需要同时遵守您所属和/或所处国家或地区的法律。</p>
            <h4>一、	协议的范围</h4>
            <p>本协议是您与北京新阅时代科技有限公司（以下简称“Storytrans”）之间关于您使用Storytrans相关服务所订立的协议。Storytrans网站即Storytrans平台（https://storytrans.com/）。</p>

            <h4>二、	账户管理与密码安全</h4>
            <p>1.您在使用Storytrans的服务时需要注册一个账户。</p>
            <p>2.您在注册账户时承诺遵守法律法规、公共秩序、社会道德，尊重公民合法权益，不得在账号注册资料中出现违法和不良信息，且您保证在注册和使用账号时，不得有以下情形：</p>
            <p>（1）违反宪法或法律法规规定；</p>
            <p>（2）利用Storytrans的网络服务系统传输任何反动、民族仇恨的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料；</p>
            <p>（3）侵犯Storytrans或第三方的知识产权和/或其他合法权益；</p>
            <p>（4）利用Storytrans网络服务系统进行任何可能对网络的正常运转造成不利影响的行为，或为非法目的而使用网络服务系统；</p>
            <p>（5）发布任何形式的广告和/或宣传第三方的相关信息；</p>
            <p>（6）以营利为目的、使用任何不正当手段或其他违反诚实信用原则的情形下为自己或他人开通本服务；</p>
            <p>（7）采取不正当手段通过测试/认证，采取不正当手段对其他用户造成影响，包括但不限于对其他用户进行虚假评价、不正当竞争或扰乱交易秩序、恶意令其他用户的工作内容产生改变等；</p>
            <p>（8）委托他人代为从事账户的相关活动，包括但不限于登入、接受任务、提供翻译或审校服务、完成任务、与Storytrans进行沟通等；</p>
            <p>（9）其他违反法律法规、公共秩序、社会道德、侵犯他人权益或可能给Storytrans造成损害（包括名誉损害）的行为。</p>
            <p>如果您违反了本条约定，相关国家机关或机构可能会对您提起诉讼、罚款或采取其他制裁措施，并要求Storytrans给予协助。造成损害的，您应依法予以赔偿，Storytrans不承担任何责任。如果Storytrans发现或收到他人举报您发布的信息违反本条约定，Storytrans有权进行独立判断并采取技术手段予以删除、屏蔽或断开链接。同时，Storytrans有权视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账户使用，无需支付您的收益，追究法律责任等措施。</p>
            <p>3. 您的账户是登录和使用本平台服务的凭证，您应妥善保管、正确和安全地使用账户信息（包括但不限于账户及密码），当您使用完毕后，应安全退出。您应对账户下发生的一切活动承担全部法律责任，无论该活动是您本人进行，还是由您授权的第三方进行的，您需要对其账户项下发生的所有行为负责。</p>
            <p>4.您应避免设置过于简单的密码，并对您的账户密码采取必要和有效的保密措施。如果因为您设置的密码过于简单导致遭受盗号或密码失窃，责任由您自行承担。Storytrans对于非因Storytrans单方原因所导致的任何账户安全、隐私保护等问题均不承担责任和所产生的各方损失。</p>

            <h4>三、使用Storytrans服务的方式</h4>
            <p>1. 使用Storytrans服务的具体方式</p>
            <p>（1）您可以通过Storytrans的https://storytrans.com/网站（以下简称“Storytrans网站”）的相关页面、或以其他Storytrans允许的方式，领取发布在Storytrans网站上的翻译任务，根据Storytrans网站的指引，完成领取的任务后在Storytrans网站相关页面提交您翻译完成的稿件（以下简称“翻译稿件”）。在此过程中Storytrans根据本协议通过在Storytrans网站上不断发布翻译任务为您提供合适的翻译工作或机会。</p>
            <p>（2）您可以通过Storytrans网站的相关页面、或以其他Storytrans允许的方式，领取发布在Storytrans网站上的审校任务，根据Storytrans网站的指引，完成领取的任务后在Storytrans网站相关页面提交您审校完成的稿件（以下简称“译文”）。在此过程中Storytrans根据本协议通过在Storytrans网站上不断发布审校任务为您提供合适的审校工作或机会。</p>
            <p>2. Storytrans提供的服务仅为您个人非商业性质的使用，除非您与Storytrans另有约定。</p>
            <p>3 您应当通过Storytrans提供或认可的方式使用本服务。您依本协议条款所取得的权利不可转让。</p>
            <p>4. 您不得使用未经Storytrans授权的插件、外挂或第三方工具对本协议项下的服务进行干扰、破坏、修改或施加其他影响。</p>
            <p>5.违反以上约定视为您严重违约。</p>

            <h4>四、自备设备</h4>
            <p>1.您应当理解，您使用Storytrans服务需自行准备与相关服务有关的终端设备（如电脑、调制解调器等装置），并承担所需的费用（如电话费、上网费等费用）。</p>
            <p>2.您理解并同意，您使用本服务时会耗用您的终端设备和带宽等资源。</p>

            <h4>五、用户收益</h4>
            <p>1.您在完成Storytrans的翻译或审校任务，经Storytrans审核验收合格后，会向您支付您完成该项任务的收益，Storytrans会在相关页面上做出提示。</p>
            <p>2.我们有权决定Storytrans所提供的翻译任务或审校任务的收益标准和支付方式，Storytrans可能会就不同的翻译任务及/或审校任务制定不同的收益标准和支付方式，也可能按照您完成翻译任务或审校任务的不同阶段确定您的不同收益。</p>
            <p>3. Storytrans可能根据实际需要随时对您的收益标准、支付方式进行修改和变更。前述修改、变更前，Storytrans将在相应页面进行通知或公告，亦或通过其他方式如email向您进行告知。如果您不同意上述修改、变更，则应停止使用该服务。</p>
            <p>4.中译外翻译任务及/或中译外审校任务以中文字符数为依据计算您的收益（不计空格）。外译中以及外译外以及对前述稿件的审校的字数统计，以外文原文的单词数计算。字数统计：字数统计根据您提交翻译稿件后Storytrans提供的最终计算统计为准。</p>
            <p>5.您可以通过您注册的Storytrans网站账户查询您的所有收益。Storytrans将在每月15号（含15号）之前向您支付上月的收益。Storytrans将通过您提供的银行账户信息或其他收款信息向您支付收益，因此，请您务必向Storytrans提供或及时更新您本人正确合法有效的银行账户信息及收款信息。否则，由此导致Storytrans无法向您支付您收益的所有责任及损失均由您个人承担。</p>
            <p>6. 本协议项下服务所产生之相应税负及代扣代缴义务（如有），您与Storytrans应各自承担。</p>

            <h4>六、翻译/审校任务质量要求</h4>
            <p>1. 翻译任务（翻译稿件）质量要求</p>
            <p>（1）翻译完整连续，无缺页、错页、错译、漏译等；</p>
            <p>（2）翻译精准得当，字词及语法正确，无字词、语法错误；</p>
            <p>（3）语句通顺、上下文具有连贯性，名称、术语前后一致，内容和文风前后连贯、流畅；</p>
            <p>（4）用语正确、精准，无知识性错误如人名、称谓、地名、时间等错误；</p>
            <p>（5）结构及逻辑清晰，层次清晰合理，不含非必要的重复内容，无逻辑错误、逻辑混乱等内容；</p>
            <p>（6）对翻译内容进行本地化（包括书名、角色名的本地化），翻译内容需符合目标语种传播地区的法律法规和风俗习惯，符合本地化的要求，无政治、宗教等敏感问题，无损害当地基本文化习俗、民族习惯、宗教信仰的内容；</p>
            <p>（7）对原作的语言、风格、故事情节、人物形象进行充分还原；</p>
            <p>（8）已经为适应目标语种地区读者的阅读习惯、文化背景做出了其他所有的必要修改。</p>
            <p>2.审校任务（译文）质量要求</p>
            <p>（1）稿件完整连续，无缺页、错页等；</p>
            <p>（2）字词及语法正确，无错别字、无病句、无语法性错误；</p>
            <p>（3）语句通顺、上下文具有连贯性；</p>
            <p>（4）用语正确、精准，无知识性错误如人名、称谓、地名、时间等错误；</p>
            <p>（5）结构及逻辑清晰，层次清晰合理，不含非必要的重复内容，无逻辑错误；</p>
            <p>（6）无损害当地基本文化习俗、民族习惯、宗教信仰的内容；</p>
            <p>（7）文本格式排版正确且上下文一致，数字、标点符号、空格、大小写、字体格式、段落格式、项目符号、目录级别、全文板式等排版问题无错误且符合甲方具体要求（如有）；</p>
            <p>（8）文中图片、图表（如有）等需与文字内容匹配；</p>
            <p>（9）文中具体内容需与目录（如有）一致。</p>
            <p>3. Storytrans将在运营能力许可范围内，尽最大努力为您提供各类翻译任务或审校任务；如因翻译任务或审校任务中所涉稿件内容不合法、不符合公序良俗、内容涉及侵权、或有其他您认为不适宜接受任务的情况，您有权拒绝接受该任务；此种情况下，您将以网站通知、站内信、短信通知、邮件通知Storytrans,以便Storytrans删除相关内容；且您同意Storytrans并不承担任何约定义务，也不承担任何违约或赔偿责任。</p>

            <h4>七、知识产权声明</h4>
            <p>Storytrans所提供的服务以及与服务有关（包括但不限于您完成翻译任务及/或审校任务）的全部信息、资料、文字、软件、声音、图片、视频、图表（包括相关的软件）的著作权、商标、商业秘密、其他知识产权、所有权或其他权利，均为Storytrans或其权利人所有，受中华人民共和国相关法律及中华人民共和国加入的国际协定和国际条约保护，除非事先获得Storytrans或其权利人的合法授权，您不得对任何该信息、资料、文字、软件、声音、图片、视频、图表进行修改、拷贝、散布、传送、展示、执行、复制、发行、授权、制作衍生著作、移转或销售。如您未遵守本条款的上述规定，在不损害其他权利的情况下，Storytrans可立即终止向您提供服务，您必须销毁任何已经获得的上述信息、资料、文字、软件、声音、图片、视频、图表。违者视为您的严重违约。</p>

            <h4>八、用户违约行为</h4>
            <p>1.您违反本协议约定，导致任何第三方损害的，您应当独立承担责任；Storytrans因此遭受损失的，您也应当一并赔偿。</p>
            <p>2. 您应按照Storytrans网站相关页面的提示完成您领取的翻译或审校任务，并按时交付相关稿件，如您未能按时完成翻译或审校任务，Storytrans网站将有权收回您领取的任务并发放给其他用户。如您多次领取任务后延迟完成任务或不完成任务，Storytrans网站视用户的行为性质，采取包括但不限于暂停或终止服务，限制、冻结或终止账户使用，无需支付您的收益，追究违约责任等措施。</p>
            <p>3.违反本协议约定或您严重违约时，Storytrans有权中止对您的服务，解除双方间的服务协议和法律关系，且无需退还应付未付您的收益，如给Storytrans造成其他损失的，Storytrans有权向您追索。</p>

            <h4>九、用户个人信息保护</h4>
            <p>1. 您在注册账户或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。Storytrans有权要求您填写个人真实身份信息，有权随时检查或核实信息，有权因疑问而限制、关闭您的账户，您有义务配合工作，并无条件接受Storytrans的决定。</p>
            <p>2. Storytrans将运用各种安全技术和程序建立完善的管理制度来保护您的个人信息，以免遭受未经授权的访问、使用或披露。</p>
            <p>3. Storytrans非常重视对未成年人个人信息的保护。若父母（监护人）希望未成年人（尤其是十岁以下子女）得以使用Storytrans的服务，必须以父母（监护人）名义申请注册，并应以法定监护人身份对服务是否符合于未成年人加以判断。Storytrans保证不对外公开或向第三方披露或提供用户注册资料及用户在使用网络服务时存储在Storytrans平台、服务器或数据库的非公开内容和信息，但下列情况除外：</p>
            <p>（1）事先获得用户的明确授权；</p>
            <p>（2）根据有关的法律法规要求；</p>
            <p>（3）按照相关政府主管部门和司法机关的要求；</p>
            <p>（4）为维护社会公众的利益；</p>
            <p>（5）为维护Storytrans的合法权益。</p>
            <p>4.其他隐私保护相关条款可参见《Storytrans用户个人信息保护及隐私政策协议》，本协议约定与《Storytrans用户个人信息保护及隐私政策协议》条款有不一致之处，适用本协议。《Storytrans用户个人信息保护及隐私政策协议》的相关条款在本协议中未约定的，依照《Storytrans用户个人信息保护及隐私政策协议》约定执行。</p>

            <h4>十、免责声明</h4>
            <p>1. Storytrans服务提供者未对以下事宜进行保证或承诺：</p>
            <p>（1）本服务将完全符合您的要求；</p>
            <p>（2）本服务将不受干扰、及时提供、安全可靠且不会出错。</p>
            <p>（3）您在Storytrans获得的任何建议和资讯，无论采用何种形式，均不视为本协议未明示规定的任何保证。</p>

            <h4>十一、服务的变更、中断或终止</h4>
            <p>1. 如因系统维护或升级的需要而需中止网络服务，我们将对该等情况尽可能事先以公告的形式向您告知。</p>
            <p>2. 在下列情形下，Storytrans有权无需征得您的同意限制您的活动、向您核实有关资料、发出警告通知、暂时中止、无限期中止及拒绝向您提供服务：</p>
            <p>（1）您违反本协议或其他Storytrans网站上公布的相关规则；</p>
            <p>（2）Storytrans接到其他用户或第三方通知，认为您或具体交易事项存在违法或不当行为，并提供相关证据；</p>
            <p>（3）因您行为而对Storytrans和/或其服务构成破坏、损害或造成其他负面影响。</p>
            <p>3. 除条第2款所述情形外，鉴于网络服务的特殊性，受相关法律法规调整、Storytrans自身业务及经营调整等原因影响，您同意Storytrans有权随时变更、中断或终止部分或全部的网络服务，该行为不视为违约。如变更、中断或终止的网络服务属于免费网络服务，Storytrans无需通知您，也无需对您或任何第三方承担任何责任。</p>
            <p>4. Storytrans有权提前30天通知您解除本协议；解除本协议时有已支付（且未完成）任务的，网站将以该任务的收益金额为限向您提供适当补偿。</p>

            <h4>十二、不可抗力</h4>
            <p>因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，可以解除本协议，协议双方均不承担任何责任。本协议中的不可抗力因素指不能预见、不能避免且不能克服的客观情况，包括但不限于战争、台风、洪水、地震等自然灾害；罢工、暴动、黑客攻击、网络病毒、电信部门技术管制、国家强制性政策或任何其他人为造成的灾难等。</p>

            <h4>十三、服务转让</h4>
            <p>您同意，Storytrans可根据自身业务运营需要将本协议项下全部或部分权利义务转让给关联公司，转让将以书面方式（网站公告或电子邮件等）通知予您，转让于通知后生效。</p>

            <h4>十四、适用法律与管辖</h4>
            <p>1. 本协议的订立、执行和解释及争议的解决均应适用中国法律。</p>
            <p>2. 如果因本服务条款产生纠纷，双方应协商一致解决；如果双方无法通过协商解决争端，任何一方均应向被告所在地有管辖权的人民法院提起诉讼。</p>

            <h4>十五、其他</h4>
            <p>1. 如您对本服务条款内容有任何疑问，可以通过以下方式与我们联系：</p>
            <p>电子邮箱：inknettranslation@gmail.com</p>
            <p>2. 您和Storytrans均为独立的主体，在任何情况下本协议不构成双方之间的代理、合伙、合营或雇佣关系。</p>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceAgreement',
    mounted () {
        this.$root.$children[0].toggleDialogHandle({
          showTop: false,
          showBottom: false
        });
    },
    destroyed () {
        this.$root.$children[0].toggleDialogHandle({
          showTop: true,
          showBottom: true
        });
    }
}
</script>
<style lang="less" scoped>
#app {
    .serviceAgreement {
        .agreement-div {
            margin-top: 20px;
            h2 {
                margin: 10px 0;
            }
            h3 {
                margin-bottom: 16px;
            }
            h4 {
                margin-top: 16px;
            }
            p {
                margin: 8px 0;
            }
        }
    }
}
</style>