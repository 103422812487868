<template>
    <div class="agreement">
        <h2>Storytrans Service Agreement</h2>
        <h3>Version Release Date: June 18, 2024</h3>
        <p>Welcome to Storytrans. Before registering as a user of "storytrans.com" (hereinafter referred to as "Storytrans" or "we") and using our products or services, please read the 'Storytrans Service Agreement' (hereinafter referred to as 'this Agreement') carefully. Please make sure you fully understand the content of each clause, especially the clauses that waive or limit liability or the clauses involving your significant rights (in this text, they might be bold, underlined, etc. in format). If you complete the registration on Storytrans or use Storytrans services, it is deemed that you have read and agreed to be bound by this Agreement. This Agreement will have a legal effect between you and Storytrans and become a legally binding document for both parties. Unless you have read and accepted all the terms of this Agreement, you are not entitled to use Storytrans services.</p>
        <p>If you are under 18 years old or other factors make you not fully capable of civil conduct, please read this Agreement under the accompaniment of your legal guardian, and pay special attention to the terms for minors.</p>
        <p>If you use Storytrans services outside Singapore, you also need to comply with the laws of your country or region when entering into or performing this Agreement.</p>
        <h4>1. Scope of the Agreement </h4>
        <p>This Agreement is an agreement between you and Inknet PTE. LTD. (hereinafter referred to as "Storytrans") regarding your use of Storytrans-related services. Storytrans refers to the Storytrans website (https://storytrans.com/).</p>
        <h4>2. Account Management and Password Security</h4>
        <p>2.1 You need to register an account to use Storytrans services.</p>
        <p>2.2 When registering an account, you promise to comply with laws and regulations, public order, and social morals, respect the lawful rights and interests of all citizens, and ensure that no illegal and harmful information appears in your personal information. In registering and using the account, you must not:</p>
        <p>(1) Violate constitutional or legal regulations;</p>
        <p>(2) Use the Storytrans network service system to transmit any reactionary, ethnic hatred, harassing, defamatory, abusive, threatening, vulgar, obscene, or other illegal information;</p>
        <p>(3) Infringe the intellectual property and/or other legal rights of Storytrans or a third party;</p>
        <p>(4) Use the Storytrans network service system for any behavior that may adversely affect the normal operation of the network, or use the network service system for illegal purposes;</p>
        <p>(5) Post any form of advertising and/or promote third-party related information;</p>
        <p>(6) Use any improper means or other circumstances that violate the principles of honesty and credibility to open Storytrans services to yourself or others for profit;</p>
        <p>(7) Use improper means to pass the test/verification, or use improper means to affect other users, including but not limited to false assessment of other users, unfair competition, actions disrupting the transaction order, maliciously causing changes to the work content of other users, etc.;</p>
        <p>(8) Commission others to engage in account-related activities, including but not limited to logging in, accepting tasks, providing translation or proofreading services, completing tasks, communicating with Storytrans, etc.;</p>
        <p>(9) Perform other actions that violate laws and regulations, public order, or social morals, infringe on the rights of others, or may cause damage to Storytrans (including reputation damage).</p>
        <p>If you violate the provisions of this clause, relevant national authorities or institutions may sue you, impose fines, or take other sanctions, and request Storytrans to assist. If any damage is caused, you shall compensate according to the laws, and Storytrans does not assume any responsibility. If Storytrans discovers or receives reports from others that the information you post violates the provisions of this clause, Storytrans has the right to make independent judgments and take technical measures to delete, block, or disconnect links. At the same time, depending on the nature of the user's behavior, Storytrans has the right to take measures including but not limited to suspending or terminating the services, restricting, freezing, or terminating the use of the account, paying no service fees, and pursuing legal responsibilities.</p>
        <p>2.3 Your account is the credential for logging in and using Storytrans services. You should keep it safe and use your account information correctly and safely (including but not limited to the account and password), and securely log out after use. You are responsible for all the activities that occur under your account, whether conducted by you personally or by a third party authorized by you, and you are responsible for all actions under your account.</p>
        <p>2.4 You should avoid setting an overly simple password and take necessary and effective confidentiality measures for your password. If your account is hacked or your password is stolen because the password you set is overly simple, you are responsible for it. Storytrans is not responsible for any issues related to account security, privacy protection, etc., that are not caused by Storytrans alone, and Storytrans shall not be held liable for any damages thus incurred to any party.</p>
        <h4>3. Ways to Use Storytrans Services</h4>
        <p>3.1 Specific ways to use Storytrans services</p>
        <p>(1) You can receive translation tasks published on the related pages of the Storytrans website or in other ways allowed by Storytrans. After completing the tasks under the guidance of the Storytrans website, submit your translation on the related pages of the Storytrans website (hereinafter referred to as "translated manuscript"). In this process, Storytrans provides suitable translation work or opportunities for you according to this Agreement by continuously publishing translation tasks on the Storytrans website.</p>
        <p>(2) You can receive proofreading tasks published on the related pages of the Storytrans website or in other ways allowed by Storytrans. After completing the tasks under the guidance of the Storytrans website, submit your proofread manuscript on the related pages of the Storytrans website (hereinafter referred to as "proofread manuscript"). In this process, Storytrans provides suitable proofreading work or opportunities for you according to this Agreement by continuously publishing proofreading tasks on the Storytrans website.</p>
        <p>3.2 The services provided by Storytrans are for your personal, non-commercial use only unless you have another agreement with Storytrans.</p>
        <p>3.3 You shall use the services in ways provided or recognized by Storytrans. The rights you obtain under the clauses of this Agreement are not transferable.</p>
        <p>3.4 You shall not use unauthorized plugins, external applications, or third-party tools to interfere with, disrupt, modify, or otherwise affect the services under this Agreement.</p>
        <p>3.5 Violation of the above provisions is regarded as your serious breach of contract.</p>
        <h4>4. Self-Equipped Equipment</h4>
        <p>4.1 You should understand that you need to prepare the terminal equipment related to the services (such as computers, modems, etc.) and bear the necessary costs (such as telephone fees, Internet access fees, etc.).</p>
        <p>4.2 You understand and agree that using the services will consume resources such as your terminal equipment and bandwidth.</p>
        <h4>5. Service Fees</h4>
        <p>5.1 After you complete the translation or proofreading tasks of Storytrans and pass the verification and approval by Storytrans, Storytrans will pay you the fee for completing the task. Storytrans will show you the settlement on the related pages.</p>
        <p>5.2 We have the right to decide the service fee standards and payment methods for the translation or proofreading tasks provided by Storytrans. Storytrans may set different service fee standards and payment methods for different translation tasks and/or proofreading tasks or determine the final fee based on the quality and/or the different stages of completing the translation tasks or proofreading tasks.</p>
        <p>5.3 Storytrans may modify and change the service fee standards and payment methods as needed at any time. Before such modifications and changes, Storytrans will notify or announce on the corresponding page or inform you by other means such as email. If you disagree with the above modifications and changes, you should stop using the services.</p>
        <p>5.4 Translation tasks from Chinese to foreign languages and/or proofreading tasks from Chinese to foreign languages calculate your service fees based on the number of Chinese characters (excluding spaces). Translation from foreign languages to Chinese and translation from foreign languages to other foreign languages, as well as proofreading of the aforementioned manuscripts, the word count is based on that of the foreign original text. </p>
        <p>Word count statistics: Word count statistics is based on the final calculation provided by Storytrans after you submit the translated or proofread manuscript.</p>
        <p>5.5 You can check all your service fees through your Storytrans account. Storytrans will pay you the service fees of the previous month before or on the last day of each month. Storytrans will pay you service fees through the bank account information or other payment information you provide, so please be sure to provide or promptly update Storytrans with your correct, legal, and valid bank account information or other payment information. Otherwise, all responsibilities and losses caused by Storytrans's inability to pay your service fees are borne by you personally.</p>
        <p>5.6 The corresponding tax and withholding obligations (if any) arising from the services under this agreement shall be borne by you and Storytrans respectively. </p>
        <h4>6. Translation/Proofreading Task Quality Requirements</h4>
        <p>6.1 Translation task (translated manuscript) quality requirements:</p>
        <p>(1) The translation is complete and continuous, with no missing pages, wrong pages, mistranslations, omissions, etc.;</p>
        <p>(2) The translation is accurate and appropriate, with correct words and grammar and no errors in word choice or grammar;</p>
        <p>(3) The sentences are smooth, and the context is coherent, with consistent names, terms, content, and style;</p>
        <p>(4) The language is correct and precise, with no factual errors such as errors in names, titles, place names, time, etc.;</p>
        <p>(5) The structure and logic are clear, with clear and reasonable transitions, with no unnecessary, repetitive content and logical errors or confusion;</p>
        <p>(6) The content is localized (including the localization of book titles and character names) and must comply with the laws, regulations, and customs of the target language's distribution area, meet the localization requirements, and contain no content that harms the basic local cultural customs, national habits, and religious beliefs;</p>
        <p>(7) The language, style, plot, and character images of the original work are fully reserved;</p>
        <p>(8) All other necessary modifications have been made to adapt to the reading habits and cultural background of the target language and area's readers.</p>
        <p>6.2 Proofreading task (proofread manuscript) quality requirements:</p>
        <p>(1) The text is complete and continuous, without missing pages, wrong pages, etc.;</p>
        <p>(2) The words and grammar are correct, with no typos, ungrammatical sentences, or grammatical errors;</p>
        <p>(3) The sentences are smooth, and the context is coherent;</p>
        <p>(4) The language is correct and precise, with no factual errors such as errors in names, titles, place names, time, etc.;</p>
        <p>(5) The structure and logic are clear, with clear and reasonable transitions, with no unnecessary, repetitive content or logical errors;</p>
        <p>(6) The content does not harm the basic local cultural customs, national habits, and religious beliefs;</p>
        <p>(7) The text format is correct and consistent throughout, with no errors in numbers, punctuation marks, spaces, capitalization, font format, paragraph format, bullet points, levels in table of contents, full-page layout, etc.;</p>
        <p>(8) Images, diagrams, and charts (if any) in the text must match the text content;</p>
        <p>(9) The specific content in the text must be consistent with the table of contents (if any).</p>
        <p>Storytrans will make the utmost effort to provide you with various translation or proofreading tasks within the scope of its operational capabilities; if the content of the translation or proofreading tasks involved is illegal, does not comply with public order and good customs, involves infringement, or has other situations that you consider inappropriate to accept the task, you have the right to refuse to accept the task. In this case, you will notify Storytrans through website notifications, website messages, SMS notifications, email notifications, etc., so that Storytrans can delete the relevant content, and you agree that Storytrans does not undertake any contractual obligations, nor does it undertake any breach of contract or compensation responsibilities.</p>
        <h4>7. Intellectual Property Statement </h4>
        <p>The services provided by Storytrans and all information, materials, text, software, audio, pictures, videos, diagrams, and charts (including related software) related to the services (including but not limited to your completion of translation tasks and/or proofreading tasks) are owned by Storytrans or its rights holders. They are protected by the relevant laws of Singapore and the international agreements and treaties that Singapore has joined. Unless previously authorized by Storytrans or its rights holders, you may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works, transfer, or sell any such information, materials, text, software, audio, pictures, videos,  diagrams, and charts. If you fail to comply with the provisions of this clause, without prejudice to other rights, Storytrans may immediately terminate the service to you, and you must destroy any information, materials, text, software, audio, pictures, videos, diagrams, and charts that you have obtained. Violation will be considered your serious breach of contract.</p>
        <h4>8. User Breach of Contract</h4>
        <p>8.1.If you breach the provisions of this Agreement and cause damage to any third party, you shall bear the responsibility independently; if Storytrans suffers losses due to this, you shall also compensate.</p>
        <p>8.2 You should complete the translation or proofreading tasks you receive according to the prompts on the relevant pages of the Storytrans website and deliver the relevant manuscripts on time. If you fail to complete the translation or proofreading tasks on time, the Storytrans website has the right to take back the tasks you received and distribute them to other users. If you repeatedly receive tasks and delay or fail to complete them, depending on the nature of the behavior, the Storytrans website may take measures including but not limited to suspending or terminating the service, restricting, freezing, or terminating the use of the account, without paying your service fees, pursuing breach of contract responsibilities, etc.</p>
        <p>8.3 If you breach the provisions of this Agreement or seriously breach the contract, Storytrans has the right to suspend the service to you, terminate the service agreement and legal relationship between both parties, and there is no need to pay the unpaid service fees to you. If it causes other losses to Storytrans, Storytrans has the right to seek recourse from you.</p>
        <h4>9. Protection of Users' Personal Information</h4>
        <p>9.1 During the process of registering an account or using this service, you may need to fill in some necessary information. If there are special provisions in national laws and regulations, you need to fill in real identity information. If the information you fill in is incomplete, you cannot use this service or will be restricted during use. </p>
        <p>Storytrans has the right to require you to fill in real personal identity information, has the right to check or verify the information at any time, has the right to restrict or close your account due to doubts, and you have the obligation to cooperate with Storytrans and unconditionally accept Storytrans's decision.</p>
        <p>9.2 Storytrans will use various security technologies and procedures to establish a complete management system to protect your personal information from unauthorized access, use, or disclosure.</p>
        <p>9.3 Storytrans attaches great importance to the protection of the personal information of minors. If parents (guardians) wish minors (especially children under ten years old) to use Storytrans's services, they must apply for registration in the name of the parents (guardians) and should judge whether the service is suitable for minors as legal guardians. Storytrans guarantees not to disclose or provide users' registration information and non-public content and information stored on the Storytrans website, servers, or databases to the public or third parties, except in the following cases:</p>
        <p>(1) With the user's clear authorization in advance;</p>
        <p>(2) According to the requirements of relevant laws and regulations;</p>
        <p>(3) According to the requirements of relevant government authorities and judicial organs;</p>
        <p>(4) To protect the interests of the public;</p>
        <p>(5) To protect the lawful rights and interests of Storytrans.</p>
        <p>9.4 Other privacy protection-related terms can be found in the "Storytrans Privacy Policy Agreement". If there are inconsistencies between the provisions of this Agreement and the "Storytrans Privacy Policy Agreement", this Agreement shall apply. The relevant terms of the "Storytrans Privacy Policy Agreement" that are not stipulated in this Agreement shall be implemented according to the "Storytrans  Privacy Policy Agreement".</p>
        <h4>10. Disclaimer</h4>
        <p>The provider of Storytrans services does not guarantee or promise the following matters:</p>
        <p>(1) This service will fully meet your requirements;</p>
        <p>(2) This service will be uninterrupted, timely provided, secure, reliable, and error-free.</p>
        <p>(3) Any advice and information you obtain from Storytrans, regardless of the form, are not regarded as any guarantees not explicitly stipulated in this Agreement.</p>
        <h4>11. Changes, Interruptions, or Termination of Services</h4>
        <p>11.1 If network services need to be suspended due to system maintenance or upgrades, we will try to inform you in advance in the form of announcements.</p>
        <p>11.2 In the following situations, Storytrans has the right to restrict your activities without your consent, verify relevant information, issue warning notices, temporarily suspend, indefinitely suspend, and refuse to provide services to you:</p>
        <p>(1) You violate this Agreement or other related rules published on the Storytrans website;</p>
        <p>(2) Storytrans receives notifications from other users or third parties, considers that you or specific transaction matters involve illegal or improper behavior, and provides relevant evidence;</p>
        <p>(3) Due to your behavior, Storytrans and/or its services are damaged, harmed, or otherwise negatively affected.</p>
        <p>11.3 Except for the situations described in Clause 11.2 above, given the special nature of network services, affected by the adjustment of relevant laws and regulations, business and operational adjustments of Storytrans, etc., you agree that Storytrans has the right to change, interrupt, or terminate part or all of the network services at any time, and such actions are not considered breaches of contract. If the changed, interrupted, or terminated network service is a free network service, Storytrans does not need to notify you, nor does it need to bear any responsibility to you or any third party.</p>
        <p>11.4 Storytrans has the right to notify you 30 days in advance to terminate this Agreement; if there are paid (and uncompleted) tasks when terminating this Agreement, the website will provide appropriate compensation limited to the service fee of the task.</p>
        <h4>12. Force Majeure </h4>
        <p>If the performance of this Agreement becomes impossible, unnecessary, or meaningless due to force majeure or other unexpected events, this Agreement may be terminated, and neither party shall bear any responsibility. The force majeure factors in this Agreement refer to objective situations that are unforeseeable, unavoidable, and insurmountable, including but not limited to war, typhoon, flood, earthquake, and other natural disasters, strikes, riots, hacker attacks, network viruses, technical control by telecommunications departments, mandatory national policies, or any other human-caused disasters, etc.</p>
        <h4>13. Service Transfer </h4>
        <p>You agree that Storytrans may transfer all or part of the rights and obligations under this Agreement to affiliated companies according to its business operation needs. The transfer will be notified to you in writing (website announcement, email, etc.), and the transfer will take effect after the notification.</p>
        <h4>14. Applicable Laws and Jurisdiction</h4>
        <p>14.1 The conclusion, execution, interpretation, and dispute resolution of this Agreement shall be governed by Singapore's laws.</p>
        <p>14.2 If disputes arise from this service agreement, both parties should resolve them through negotiation; if the dispute cannot be resolved through negotiation, either party may file a lawsuit in the court with jurisdiction where the defendant is located.</p>
        <h4>15. Others</h4>
        <p>15.1 If you have any questions about the content of this service agreement, you can contact us via email at inknettranslation@gmail.com.</p>
        <p>15.2 You and Storytrans are independent entities; under no circumstances does this Agreement constitute an agency, partnership, joint venture, or employment relationship between the parties.</p>

    </div>
  </template>
  
  <script>
  export default {
      name: 'ServiceAgreement',
      mounted () {
          this.$root.$children[0].toggleDialogHandle({
            showTop: false,
            showBottom: false
          });
      },
      destroyed () {
          this.$root.$children[0].toggleDialogHandle({
            showTop: true,
            showBottom: true
          });
      }
  }
  </script>
  
  <style lang="less" scoped>
  .agreement {
    width: 970px;
    margin: 0 auto;
    padding-bottom: 50px;

    h2 {
        margin: 10px 0;
    }
    h3 {
        margin-bottom: 16px;
    }
    h4 {
        margin-top: 16px;
    }
    p {
        margin: 8px 0;
    }
  }
  </style>
  