/*
 * @Description:
 * @FilePath: \haiwai_zb_pc\src\router\index.js
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 10:59:34
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-01-29 11:11:27
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
// import Login from "../views/Login.vue";
// import Regest from "../views/Regest.vue";
// import Resetpwd from "../views/Resetpwd.vue";
import Testprev from "../views/TestPrev.vue";
import Reviewprev from "../views/ReviewPrev.vue";
import Fy from "../views/Fy.vue";
import Review from "../views/Review.vue";
import Fyresult from "../views/Fyresult.vue";
import ReviewResult from "../views/ReviewResult.vue";
import NFD from "../views/NFD.vue";
import Acountactive from "../views/Acountactive.vue";
import Qa from "../views/Qa.vue";
import UserInfoPrivacy from "../views/UserInfoPrivacy.vue"
import ServiceAgreement from "../views/ServiceAgreement.vue"
import UserInfoPrivacyEn from "../views/UserInfoPrivacyEn.vue"
import ServiceAgreementEn from "../views/ServiceAgreementEn.vue"
import { setCookie, getCookie, clearCookie } from "../plugins/cookie";
import { Loading, Message } from "element-ui";
import i18n, { loadLanguageAsync } from "@/i18n/i18n";
import { getQueryString } from "../utils";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
    // }, {
    //   path: "/login",
    //   name: "login",
    //   component: Login,
    // }, {
    //   path: "/register",
    //   name: "register",
    //   component: Regest,
    // }, {
    //   path: "/resetpwd",
    //   name: "resetpwd",
    //   component: Resetpwd,
  },
  {
    path: "/testprev/:id",
    name: "testprev",
    component: Testprev
  },
  {
    path: "/reviewprev/:id",
    name: "Reviewprev",
    component: Reviewprev
  },
  {
    path: "/fy/:id",
    name: "fy",
    component: Fy,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/review/:id",
    name: "Review",
    component: Review,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/fyresult/:id",
    name: "fyresult",
    component: Fyresult,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/reviewresult/:id",
    name: "reviewresult",
    component: ReviewResult,
    meta: {
      requireAuth: true
    }
  },
  {
    path: "/acountactive",
    name: "acountactive",
    component: Acountactive
  },
  {
    path: "/qa",
    name: "qa",
    component: Qa
  },
  {
    path: "/userinfoprivacy",
    name: "userinfoprivacy",
    component: UserInfoPrivacy
  },
  {
    path: "/serviceagreement",
    name: "serviceagreement",
    component: ServiceAgreement
  },
  {
    path: "/userinfoprivacy_en",
    name: "userinfoprivacy",
    component: UserInfoPrivacyEn
  },
  {
    path: "/serviceagreement_en",
    name: "serviceagreement",
    component: ServiceAgreementEn
  },
  {
    path: "*",
    // redirect: "/",
    name: "404",
    component: NFD
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// 语言优先级：后台传递的lang > 本地存储的lang > 浏览器语言 > 默认语言
const browserLang = (navigator.language || navigator.userLanguage)?.slice(0, 2) || "zh";
const backendLang = getQueryString(window.location.href, "lang"); // 从后台跳转过来时，会携带lang参数，优先级最高，并同步本地存储的语言
const localLang = window.localStorage.getItem("lang");
const lang = backendLang || localLang || browserLang || "cn";
if (backendLang) {
  window.localStorage.setItem("lang", backendLang);
}
router.beforeEach(async (to, from, next) => {
  try {
    await loadLanguageAsync(lang);
  } catch (error) {
    console.log(error);
  }

  if (to.meta.requireAuth) {
    // 登录过期校验
    let expire = window.sessionStorage.getItem("expire");
    let token = window.sessionStorage.getItem("token");
    if (expire && Number(expire) < new Date().getTime()) {
      window.sessionStorage.clear();
      Message.error(i18n.t("login.signInExpire"));
      window.setTimeout(() => {
        router.app.$children[0].toggleDialogHandle({
          dialogType: 1,
          show: true
        });
      }, 1000);
    } else if (!token) {
      Message.error(i18n.t("login.pleaseLogin"));
      window.setTimeout(() => {
        router.app.$children[0].toggleDialogHandle({
          dialogType: 1,
          show: true
        });
      }, 1000);
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
});

export default router;
