/*
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\api\home.js
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-25 14:08:10
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-01-25 19:10:12
 */
export const homeApi = "/hwyc-fanyi/home/index"
export const loginApi = "/hwyc-fanyi/user/login"
export const registerApi = "/hwyc-fanyi/user/register"
export const resetPwdApi = "/hwyc-fanyi/user/updatePwd"
export const yzmApi = "/hwyc-fanyi/user/checkCode"
export const logoutApi = "/hwyc-fanyi/user/logOut"
export const refreshCodeApi  ="/hwyc-fanyi/user/imgCode"
export const loginCallBackApi  ="/hwyc-fanyi/user/loginCallBack"