<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\components\Top.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 15:48:16
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-02-02 20:54:26
-->
<template>
  <div class="top">
    <div class="w clearfix">
      <div class="logo fl">
        <a href="/"><img class="log" :src="logoPng" alt=""/></a>
      </div>
      <div v-if="isErrPage">
        <div
          class="login_reg_welcome fl"
          v-if="
            $route.path.includes('login') ||
              $route.path.includes('register') ||
              $route.path.includes('resetpwd')
          "
        >
          欢迎注册Storytrans，期待你的加入
        </div>

        <div
          class="login fr"
          v-if="
            !logined &&
              !(
                $route.path.includes('login') ||
                $route.path.includes('register') ||
                $route.path.includes('resetpwd')
              )
          "
        >
          <div
            class="btn lg"
            @click="$emit('toggle-dialog', { dialogType: 1, show: true })"
          >
            {{ $t("signIn") }}
          </div>
          <div
            class="btn zc"
            @click="$emit('toggle-dialog', { dialogType: 2, show: true })"
          >
            {{ $t("signUp") }}
          </div>
        </div>

        <div class="logined fr" v-if="logined">
          <div class="username">Hi, {{ userInfo.name || "尊敬的用户" }}</div>
          <div class="line">|</div>
          <div class="logout" @click="handleLogout">{{ $t("logout") }}</div>
        </div>

        <div class="lang-drop fr">
          <el-dropdown trigger="click" @command="handleLangChange">
            <span class="lang-drop-link">
              {{ $i18n.locale === "cn" ? "中文" : "English" }}
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="cn">中文</el-dropdown-item>
              <el-dropdown-item command="en">English</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- <div
          class="back_home fr"
          v-if="
            $route.path.includes('login') ||
            $route.path.includes('register') ||
            $route.path.includes('resetpwd')
          "
          @click="$router.push('/')"
        >
          返回首页 &gt;
        </div> -->

        <div class="login">
          <div
            class="back_home fr btn zc"
            v-if="$route.path.includes('qa')"
            @click="$router.push('/')"
          >
            {{ $t("backHome") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { logoutApi } from "@/api/home.js";
import logo from "@/assets/rlog.png";
import logoEn from "@/assets/rlog_en.png";
import { delQueryString } from "../utils";

export default {
  name: "top",
  data() {
    return {
      logined: false,
      userInfo: {
        name: "尊敬的用户"
      },
      isErrPage: true
    };
  },
  computed: { 
    logoPng() {
      return this.$i18n.locale === "cn" ? logo : logoEn;
    }
  },
  mounted() {
    this.$route.name == "404"
      ? (this.isErrPage = false)
      : (this.isErrPage = true);
    this.updateUserinfo();
  },
  updated() {
    // this.updateUserinfo();
  },
  methods: {
    updateUserinfo() {
      this.logined = window.sessionStorage.getItem("token") ? true : false;
      if (this.logined) {
        let info = window.sessionStorage.getItem("userInfo");
        this.userInfo = info
          ? JSON.parse(info)
          : {
              name: "尊敬的用户"
            };
      }
    },

    async handleLogout() {
      if (!window.sessionStorage.getItem("token")) {
        window.reload();
      }

      let res = await this.$axios({
        url: logoutApi,
        method: "post",
        data: {
          token: window.sessionStorage.getItem("token") || ""
        }
      });
      if (res.data.status == 0) {
        window.sessionStorage.clear();

        this.$message.success(this.$t("login.logoutSuc"));
        this.$router.push("/");
        window.setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        this.$message.error(
          res.data.message
            ? res.data.message
            : `${this.$t("login.logoutFail")} Code: ${res.data.status}`
        );
      }
    },

    handleLangChange(lang) {
      if (lang === this.$i18n.locale) return;
      window.localStorage.setItem("lang", lang);
      // 删除url中的lang参数
      window.location.replace(delQueryString(window.location.href, "lang"));
    }
  }
};
</script>
<style lang="less" scoped>
.top {
  .log {
    width: auto;
    height: 80%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }
  width: 100%;
  height: 61px;
  background: #fff;
  box-shadow: 0px -1px 0px 0px rgba(238, 238, 238, 1);
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  .w {
    .logo {
      a {
        display: inline-block;
        height: 60px;
        line-height: 60px;
        font-size: 27px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: bold;
        color: #1077ff;
        text-align: center;
        position: relative;
      }
    }

    .login_reg_welcome {
      margin-left: 135px;
      margin-top: 21px;
      height: 24px;
      font-size: 11px;
      font-weight: 500;
      color: #4c4c4c;
      line-height: 17px;
      height: 17px;
      font-size: 11px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #4c4c4c;
      line-height: 17px;
    }

    .login {
      height: 60px;
      line-height: 60px;

      .btn {
        margin-left: 10.6px;
        cursor: pointer;
        margin-top: 18px;
        float: left;
        // width: 47px;
        height: 25px;
        padding: 0 12px;
        line-height: 25px;
        border-radius: 3px;
        border: 1px solid #d3e6ff;
        text-align: center;
        font-size: 11px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #1077ff;

        &.lg {
          background: rgba(211, 230, 255, 1);
        }
      }
    }

    .logined {
      height: 60px;
      line-height: 60px;
      div {
        display: inline-block;
      }
      .username {
        font-size: 11px;
        font-weight: bold;
        color: #4c4c4c;
      }
      .line {
        padding: 0 20px;
        vertical-align: top;
      }
      .logout {
        cursor: pointer;
        font-size: 11px;
        font-weight: bold;
        color: #1077ff;
      }
    }

    .lang-drop {
      margin: 18px 10.6px 0 0;

      &-link {
        display: inline-block;
        height: 25px;
        padding: 0 12px;
        line-height: 25px;
        border-radius: 3px;
        border: 1px solid #d3e6ff;
        text-align: center;
        font-size: 11px;
        font-weight: 500;
        color: #1077ff;
        cursor: pointer;
      }
    }

    .back_home {
      cursor: pointer;
      height: 60px;
      line-height: 60px;
      height: 17px;
      font-size: 11px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #1077ff;
      &.zc {
        padding: 0 12px;
        margin-right: 30px;
      }
    }
  }
}
</style>
