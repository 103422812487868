/*
 * @Description:
 * @FilePath: \haiwai_zb_pc\src\plugins\util.js
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-25 10:15:22
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-01-25 17:57:52
 */
//全角半角校验
export const issbccase = strTmp => {
  for (var i = 0; i < strTmp.length; i++) {
    if (strTmp.charCodeAt(i) > 128) {
      return true;
      break;
    }
  }
  return false;
};
// 特殊符号检测
export const testTSZF = value => {
  var teshu = new RegExp(
    "[`~!@#$^&*()=|{}':;',\\[\\].<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]"
  );
  return teshu.test(value);
};
// 检测空格
export const testSpace = value => {
  return value.indexOf(" ") != -1;
};

// 检测邮箱
export const testEmail = email => {
  return /^[a-zA-Z0-9_\-.]+@([a-zA-Z0-9][a-zA-Z0-9-]*\.)+(com|cn|net|org|com.cn)$/.test(
    email
  );
};

// 数字字母 6-16
export const testPWD = pwd => {
  if (/\d/.test(pwd) && /[a-zA-Z]/.test(pwd)) {
    return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(pwd);
  } else {
    return false;
  }
};

// 打点方法
export const logAddUserId = LogObj => {
  let userInfo = window.sessionStorage.getItem("userInfo");
  if (userInfo) {
    userInfo = JSON.parse(userInfo);
    LogObj.uid = userInfo.id;
    LogObj.map.uid = userInfo.id;
  }
  return LogObj;
};
