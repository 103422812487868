<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\Home.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 10:59:34
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-03-25 17:36:17
-->
<template>
  <div class="home">
    <Banner></Banner>
    <JoinSelect :taskList="taskList"></JoinSelect>
    <JoinSelectSkeleton v-if="taskList.length == 0"></JoinSelectSkeleton>
    <AcountActive v-if="showActiveAcount" @hideMask="hideMask" :pass="pass" />
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/Home/Banner.vue";
import JoinSelect from "@/components/Home/JoinSelect.vue";
import JoinSelectSkeleton from "@/components/Home/JoinSelectSkeleton.vue";
import AcountActive from "@/components/AcountActive/index.vue";
import { homeApi, login, loginCallBackApi } from "@/api/home";
export default {
  name: "Home",
  data() {
    return {
      taskList: [],
      showActiveAcount: false,
      pass: false
    };
  },
  components: { JoinSelect, Banner, AcountActive, JoinSelectSkeleton },
  created() {
    if(this.$route.query.loginCallback){
      this.handleToken();
    }
  },
  mounted() {
    this.getHomeData();
    if (
      this.$route.query.active &&
      !window.localStorage.getItem("showLoginTipDone")
    ) {
      window.localStorage.setItem("showLoginTipDone", true);
      this.showActiveAcount = true;
      if (this.$route.query.active == "true") {
        this.pass = true;
      } else {
        this.pass = false;
      }
    }
    if (window.sessionStorage.getItem("token")) {
      this.hideMask();
    }
  },
  methods: {
    async handleToken(){
      let {data} = await this.$axios({
          url: loginCallBackApi,
          method: "post",
          data: {loginCallback: this.$route.query.loginCallback }
        });
        if (data.status == 0) {
          window.sessionStorage.setItem("token", data.data.token);
          window.sessionStorage.setItem(
            "userInfo",
            JSON.stringify({
              name: data.data.name,
              id: data.data.userId
            })
          );
          window.sessionStorage.setItem("settlementCurrency", data.data.settlementCurrency);
          // 登录过期设置
          window.sessionStorage.setItem(
            "expire",
            new Date().getTime() + 1000 * 60 * 60
          );
          this.$message.success(this.$t("login.signInSuc"));
          window.location.href = window.location.href.split("?")[0];
        }
    },
    getHomeData() {
      this.$axios({
        url: homeApi,
        method: "post"
      })
        .then(res => {
          if (res.data.status == 0) {
            this.taskList = res.data.data || [];
          } else {
            this.$message.error(this.$t("reqPageErr"));
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    hideMask() {
      this.showActiveAcount = false;
    }
  }
};
</script>
