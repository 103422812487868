<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\views\NFD.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-21 19:23:15
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-01-21 19:35:31
-->
<template>
  <div class="mask">
    <div>
      <img src="../assets/404.png" />
      <p> {{ $t("pageNotFound") }} </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "regest",
};
</script>

<style lang="less" scoped>
  .mask{
     width: 417px;
     margin: 83px auto;
     margin-bottom: 360px;
    img{
      width: 417px;
      height: 324;
    }
    p{
      height: 27px;
      font-size: 19px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #333333;
      line-height: 27px;
      text-align: center;
    }
  }
</style>