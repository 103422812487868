<!--
 * @Description: 
 * @FilePath: \haiwai_zb_pc\src\components\AcountActive\index.vue
 * @Version: 1.0
 * @Autor: CuiGang
 * @Date: 2021-01-23 16:08:49
 * @LastEditors: CuiGang
 * @LastEditTime: 2021-01-28 10:50:06
-->
<template>
  <div class="active_center" @click.stop="handleClick">
    <img
      class="cls"
      src="../../assets/ic_close.png"
      alt=""
      @click.stop="hideMask"
    />
    <img class="icon" src="../../assets/ic_anouncement.png" alt="" />
    <p v-if="pass" v-html="$t('login.accountUnactiveTip')"></p>
    <p
      v-else
      v-html="$t('accountActiveFail')"
      style="margin-top: -10px"
    ></p>
  </div>
</template>
<script>
export default {
  props: ["pass"],
  name: "active_center",
  methods: {
    hideMask() {
      this.$emit("hideMask");
    },
    handleClick() {
      if (this.pass) {
        this.$emit("hideMask");
        this.$root.$children[0].toggleDialogHandle({
          dialogType: 1,
          show: true
        });
      } else {
        return;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.active_center {
  position: fixed;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 533px;
  height: 267px;
  // background: url(../../assets/_bg.png) no-repeat center;
  // background-size: 100% 100%;
  background: rgba(0, 0, 0, 0.9);
  text-align: center;
  .icon {
    display: inline-block;
    width: 57px;
    height: 57px;
    margin-top: 53px;
    margin-bottom: 32px;
  }
  p {
    font-size: 21px;
    cursor: pointer;
    font-weight: bold;
    color: #ffffff;
    line-height: 37px;
    font-family: PingFang-SC-Bold, PingFang-SC;
  }
  .cls {
    position: absolute;
    width: 27px;
    height: 27px;
    right: 27px;
    cursor: pointer;
    top: 10px;
  }
}
</style>
